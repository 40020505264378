import { initMerchantSessionData, initOperationSessionData, initRepresentativeSessionData, initSystemSettingMaster } from "../constance/sessionData";
import { atobaraiRequirementsType, atobaraiSystemType, bankRequirementsType, bankSystemType, creditRequirementsType, creditSystemType, cvsRequirementsType, cvsSystemType, economicAtobaraiDataType, economicBankDataType, economicCreditDataType, economicCvsDataType, initAtobaraiSettingMasterSessionDataType, initBankSettingMasterSessionDataType, initCreditSettingMasterSessionDataType, initCvsSettingMasterSessionDataType, initEconomicConditionMasterSessionDataType, initEconomicSessionDataType, initMerchantSessionDataType, initOperationSessionDataType, initRepresentativeSessionDataType, initSystemSettingMasterType, loginResponseDataType, loginResponseEconomicMasterAtobaraiType, loginResponseEconomicMasterBankType, loginResponseEconomicMasterCreditType, loginResponseEconomicMasterCvsType, loginResponseEntryInfoShopType, setShopDataHandlerType } from "../constance/typeModel";
import { paymentSiteChangeToPhraseHandler } from "./paymentSiteToCode";

//ショップ情報に固定値を挿入するハンドラ
export const reviewContentInsertHandler = (response: loginResponseDataType, reviewContentInsert: loginResponseEntryInfoShopType) => {
    let reviewContentMasterPlusShopAddition: setShopDataHandlerType = {};
        let masterKeyArray: string[] = [];
        // マスター情報にshop_addition_を追加
        for(let key in response.masters.shop_addition_masters){
            let keyName = 'shop_addition_' + key
            masterKeyArray.push(keyName)
            reviewContentMasterPlusShopAddition = {...reviewContentMasterPlusShopAddition, [keyName]: response.masters.shop_addition_masters[key]}
        }
        for(let shopKey in reviewContentInsert){
            if(!masterKeyArray.includes(shopKey)){
                reviewContentMasterPlusShopAddition = {...reviewContentMasterPlusShopAddition, [shopKey]:reviewContentInsert[shopKey]}
            }
        }
        return reviewContentMasterPlusShopAddition
}

//エントリーデータ成型用ハンドラ
export const entryDataSuitHandler = (loginResponse: loginResponseDataType) => {
    //店舗_郵便番号
    let merchant_register_post_code = ""
    let merchant_register_post_code3 = "";
    let merchant_register_post_code4 = "";
    //店舗電話番号
    let merchant_phone_number_before = ""
    let merchant_phone_number_middle = ""
    let merchant_phone_number_after = ""
    //代表者_誕生日
    let representative_birthday_CE = "";
    let representative_birthday_month = "";
    let representative_birthday_day = "";
    let operator_phone_number_before = ""
    let operator_phone_number_middle = ""
    let operator_phone_number_after = ""
    let support_operator_email = ""
    let support_operator_phone_number = ""
    let support_operator_phone_number_before = ""
    let support_operator_phone_number_middle = ""
    let support_operator_phone_number_after = ""
    let shop_addition_authentication_imple_flag = ''
    let shop_addition_security_code_imple_flag = ''
    // let shop_addition_use_continuation = false
    let merchantObj = {};
    let representativeObj = {};
    let operatorObj = {};
    let bankObj: {
        bank_account_category : number | string
    } = {
        bank_account_category : ""
    };
    let siteStatusObj = {
        site_status_code: 0,
        site_status_method: 0
    };
    let productCodeFlags = {
        digital_email: false,
        sell_goods: false,
        service: false
    }
    const responseMerchant = loginResponse.entry_info_merchant

    //ログイン時の加盟店データをsessionのデータ単位(merchant,representative,orepator)に分割
    const objDevide = (value: string) => {
        const setInitData = (value: string) => {
            if(value === "merchant") return initMerchantSessionData
            if(value === "representative") return initRepresentativeSessionData
            if(value === "operator") return initOperationSessionData
            return {}
        } 
        let devidedObj: initMerchantSessionDataType | initRepresentativeSessionDataType | initOperationSessionDataType | {} = setInitData(value)

        if(!Object.keys(devidedObj).length) return {}
        for(let key in responseMerchant){
            if(key.split('_')[0] !== value) continue
            let keyValue = responseMerchant[key] || ""
            devidedObj = {
                ...devidedObj, 
                [key] : keyValue
            }
        }
        if("merchant_annual_business" in devidedObj && responseMerchant.merchant_annual_business === 0){
            devidedObj.merchant_annual_business = 0
        } 

        return devidedObj
    }

    if(responseMerchant){
        let merchantPhoneArray = ['','','']
        let operatorPhoneArray = ['','','']
        let supportOperatorPhoneArray = ['','','']
        if(responseMerchant.merchant_phone_number){
            merchantPhoneArray = responseMerchant.merchant_phone_number.split('-')
        }
        if(responseMerchant.operator_phone_number){
            operatorPhoneArray = responseMerchant.operator_phone_number.split('-')
        }
        if(responseMerchant.support_operator_phone_number){
            supportOperatorPhoneArray = responseMerchant.support_operator_phone_number.split('-')
        }
        merchant_register_post_code = responseMerchant.merchant_register_post_code.replace('-','')
        merchant_register_post_code3 = merchant_register_post_code.substring(0, 3);
        merchant_register_post_code4 = merchant_register_post_code.substring(3, 7);
        merchant_phone_number_before = merchantPhoneArray[0]   
        merchant_phone_number_middle = merchantPhoneArray[1]
        merchant_phone_number_after = merchantPhoneArray[2]
        if(responseMerchant.representative_birthday !== "0000-00-00" && responseMerchant.representative_birthday !== "" && responseMerchant.representative_birthday !== null){
            representative_birthday_CE = responseMerchant.representative_birthday.split('-')[0]
            //誕生月は一桁の場合、十の位を排除
            representative_birthday_month = (responseMerchant.representative_birthday.split('-')[1].substr(0,1) === '0' 
                ? responseMerchant.representative_birthday.split('-')[1].substr(1,2) 
                : responseMerchant.representative_birthday.split('-')[1])
            representative_birthday_day = (responseMerchant.representative_birthday.split('-')[2].split(' ')[0].substr(0,1) === '0' 
                ? responseMerchant.representative_birthday.split('-')[2].split(' ')[0].substr(1,2) 
                : responseMerchant.representative_birthday.split('-')[2].split(' ')[0])
        }
        
        support_operator_email = responseMerchant.support_operator_email
        operator_phone_number_before = operatorPhoneArray[0]
        operator_phone_number_middle = operatorPhoneArray[1]
        operator_phone_number_after = operatorPhoneArray[2]
        support_operator_phone_number = responseMerchant.support_operator_phone_number
        support_operator_phone_number_before = supportOperatorPhoneArray[0]
        support_operator_phone_number_middle = supportOperatorPhoneArray[1]
        support_operator_phone_number_after = supportOperatorPhoneArray[2]
        merchantObj = objDevide('merchant')
        representativeObj = objDevide('representative')
        operatorObj = objDevide('operator')
    }
    if(loginResponse.entry_info_bank && loginResponse.entry_info_bank.bank_account_category !== null){
        bankObj = loginResponse.entry_info_bank
        bankObj = {
            ...bankObj, 
            bank_account_category:bankObj.bank_account_category.toString(),
        }
    }
    if(loginResponse.entry_info_shop){
        if(loginResponse.entry_info_shop.shop_addition_site_status !== null){
            siteStatusObj = siteStatusChangeToRadio(loginResponse.entry_info_shop.shop_addition_site_status)
        }
        if(loginResponse.entry_info_shop.shop_addition_site_status !== null){
            productCodeFlags = productContentCodeToFlag(loginResponse.entry_info_shop.shop_addition_product_content_code)
        }
    }

    if(loginResponse.services && loginResponse.services.credit){
        shop_addition_authentication_imple_flag = loginResponse.services.credit.system_setting.threed_secure ? "1" : "3"
        shop_addition_security_code_imple_flag = loginResponse.services.credit.system_setting.cvv ? "1" : "3"
    }
    
    const merchantData = {
        ...merchantObj,
        merchant_register_post_code3: merchant_register_post_code3,
        merchant_register_post_code4: merchant_register_post_code4,
        merchant_phone_number_before: merchant_phone_number_before,
        merchant_phone_number_middle: merchant_phone_number_middle,
        merchant_phone_number_after: merchant_phone_number_after,
    }
    const representativeData = {
        ...representativeObj,
        representative_birthday_CE: representative_birthday_CE,
        representative_birthday_month: representative_birthday_month,
        representative_birthday_day: representative_birthday_day,
    }
    const operatorData = {
        ...operatorObj,
        operator_phone_number_before:operator_phone_number_before,
        operator_phone_number_middle:operator_phone_number_middle,
        operator_phone_number_after:operator_phone_number_after,
        support_operator_email: support_operator_email,
        support_operator_phone_number: support_operator_phone_number,
        support_operator_phone_number_before: support_operator_phone_number_before,
        support_operator_phone_number_middle: support_operator_phone_number_middle,
        support_operator_phone_number_after: support_operator_phone_number_after,
    }
    const reviewContentInsert = {
        ...loginResponse.entry_info_shop,
        shop_addition_product_content_code_digital_email:productCodeFlags.digital_email,
        shop_addition_product_content_code_sell_goods:productCodeFlags.sell_goods,
        shop_addition_product_content_code_service:productCodeFlags.service,
        shop_addition_site_status_code:siteStatusObj.site_status_code,
        shop_addition_site_status_method:siteStatusObj.site_status_method,
        shop_addition_non_infringing_product_presence_absence_flag: false,
        shop_addition_available_product_flag: false,
        shop_addition_authentication_imple_flag: shop_addition_authentication_imple_flag,
        shop_addition_security_code_imple_flag: shop_addition_security_code_imple_flag,
    }
    
    return {merchantData,representativeData,operatorData,bankObj,reviewContentInsert}
}

//ショップ状態をラジオボタンの形に成型
const siteStatusChangeToRadio = (siteStatus: string) => {
    let siteStatusObj = {
        site_status_code:0,
        site_status_method:0
    }
    if(siteStatus === "0"){
        siteStatusObj = {
            site_status_code:0,
            site_status_method:0
        }
    }else if(siteStatus === "1"){
        siteStatusObj = {
            ...siteStatusObj,
            site_status_code:1
        }
    }else if(siteStatus === "2"){
        siteStatusObj = {
            site_status_code:0,
            site_status_method:1
        }
    }
    return siteStatusObj
}

//商品コンテンツをフラグ化
const productContentCodeToFlag = (contentCode: string) => {
    let flags = {sell_goods:false, service:false, digital_email:false}

    if(contentCode.includes('SELL_GOODS')){
        flags = {
            ...flags, 
            sell_goods:true
        }
    } 
    if(contentCode.includes('SERVICE')){
        flags = {
            ...flags, 
            service:true
        }
    } 
    if(contentCode.includes('DIGITAL_EMAIL')){
        flags = {
            ...flags, 
            digital_email:true
        }
    } 
    
    return flags
}

//システムセッティングマスターセットハンドラ
export const systemSettingMasterSetHandler = (response: loginResponseDataType) => {
    let systemSettingData = initSystemSettingMaster
    const systemSettingMasters = response.masters.system_setting_masters

    const setAgentOrMerchantData = (service: string, side: string) => {
        let sideData = {}
        const agentOrMerchantData = systemSettingMasters[service][side]
        if(typeof agentOrMerchantData === "string"){
            return sideData
        }
        for(let item in agentOrMerchantData){
            let isFlag = item.includes('_flag')
            let deleteFlagName = item.replace('_flag','')
            let itemName = service + '_' + deleteFlagName + '_' + side
            itemName = isFlag ? itemName + '_flag' : itemName
            sideData = {
                ...sideData, 
                [itemName]:agentOrMerchantData[item]
            }
        }
        return sideData
    }
    // 各サービスのシステム設定フラグをセットする関数
    const setSystemSettingFlag = (service: string) => {
        let paymentName = service + '_payment_type'
        let resultData = {}
        const agentData = setAgentOrMerchantData(service, 'agent')
        const merchantData = setAgentOrMerchantData(service, 'merchant')
        resultData = {
            ...resultData, 
            [paymentName]:systemSettingMasters[service].payment_type,
            ...agentData,
            ...merchantData,
        }
        return resultData
    }

    const creditSettingData = setSystemSettingFlag('credit')
    const bankSettingData = setSystemSettingFlag('bank')
    const cvsSettingData = setSystemSettingFlag('cvs')
    const atobaraiSettingData = setSystemSettingFlag('atobarai')

    systemSettingData = {
        ...systemSettingData, 
        ...creditSettingData,
        ...bankSettingData,
        ...cvsSettingData,
        ...atobaraiSettingData
    } 

    return systemSettingData
}

// 経済状況マスタ情報の成型ハンドラ
export const economicConditionMasterInsertHandler = (
    loginResponse: loginResponseDataType, 
    economicConditionMasterInfoData: initEconomicConditionMasterSessionDataType
) => {
    let economicConditionMasterData: {
        credit?: loginResponseEconomicMasterCreditType,
        cvs?: loginResponseEconomicMasterCvsType,
        bank?: loginResponseEconomicMasterBankType,
        atobarai?: loginResponseEconomicMasterAtobaraiType,
    } = {};
    
    const responseMasterData = loginResponse.masters

    const insertEconomicMaster = <T,U extends {payment_site: string}>(masterSerivceData: U, masterData: T): U => {
        let preEconomicConditionMasterData = {...masterSerivceData}
        for(let item in masterData){
            let value = masterData[item]
            preEconomicConditionMasterData = {
                ...preEconomicConditionMasterData, 
                [item]:value
            }
        }
        if("payment_site" in preEconomicConditionMasterData){
            preEconomicConditionMasterData.payment_site = paymentSiteChangeToPhraseHandler(preEconomicConditionMasterData.payment_site)
        }
        return preEconomicConditionMasterData
    }
    const creditMasterData = insertEconomicMaster<initCreditSettingMasterSessionDataType,loginResponseEconomicMasterCreditType>(economicConditionMasterInfoData.credit, responseMasterData.credit_setting_masters)
    const bankMasterData = insertEconomicMaster<initBankSettingMasterSessionDataType,loginResponseEconomicMasterBankType>(economicConditionMasterInfoData.bank, responseMasterData.bank_setting_masters)
    const cvsMasterData = insertEconomicMaster<initCvsSettingMasterSessionDataType,loginResponseEconomicMasterCvsType>(economicConditionMasterInfoData.cvs, responseMasterData.cvs_setting_masters)
    const masterAtobaraiData = insertEconomicMaster<initAtobaraiSettingMasterSessionDataType,loginResponseEconomicMasterAtobaraiType>(economicConditionMasterInfoData.atobarai, responseMasterData.atobarai_setting_masters)

    economicConditionMasterData = {
        credit: creditMasterData,
        bank: bankMasterData,
        cvs: cvsMasterData,
        atobarai: masterAtobaraiData
    }
    
    return economicConditionMasterData
}

//経済条件マスタに項目がない場合表示しない制御をするフラグ
export const setServiceShowFlagHandler = (masterData: {
    credit?: loginResponseEconomicMasterCreditType,
    bank?: loginResponseEconomicMasterBankType,
    cvs?: loginResponseEconomicMasterCvsType,
    atobarai?: loginResponseEconomicMasterAtobaraiType,
}) => {
    let economicDividedObj = {
        credit:false,
        bank:false,
        cvs:false,
        atobarai:false,
    }
    for(let service in masterData){
        economicDividedObj = {
            ...economicDividedObj, 
            [service]:true
        }
    }
    return economicDividedObj
}

//EntryAPIのポスト用経済条件データにセット
export const economicInsertHandler = (
    loginResponse: loginResponseDataType,
    economicInfoData: initEconomicSessionDataType,
    demandCode: string
) => {
    let economicData = {...economicInfoData}

    const creditData = setServiceEconomicData<creditSystemType,creditRequirementsType,economicCreditDataType>('credit',loginResponse,economicInfoData.credit,demandCode)
    economicData = {
        ...economicData,
        credit:creditData
    }

    const bankData = setServiceEconomicData<bankSystemType,bankRequirementsType,economicBankDataType>('bank',loginResponse,economicInfoData.bank,demandCode)
    economicData = {
        ...economicData,
        bank:bankData
    }
    
    const cvsData = setServiceEconomicData<cvsSystemType,cvsRequirementsType,economicCvsDataType>('cvs',loginResponse,economicInfoData.cvs,demandCode)
    economicData = {
        ...economicData,
        cvs:cvsData
    }

    const atobaraiData = setServiceEconomicData<atobaraiSystemType,atobaraiRequirementsType,economicAtobaraiDataType>('atobarai',loginResponse,economicInfoData.atobarai,demandCode)
    economicData = {
        ...economicData,
        atobarai:atobaraiData
    }
    
    //service_code_listの反映
    if(loginResponse.service_code_list){
        economicData = {
            ...economicData, 
            service_code_list: loginResponse.service_code_list
        }
    }

    return economicData
}

const setServiceEconomicData = <T,U extends {payment_site: string},O extends {system_setting:T, economic_requirement:U}>(
    service: string, 
    loginResponse: loginResponseDataType,
    economicServiceData: O,
    demandCode: string
) => {
    const masterKey = service + '_setting_masters'
    const serviceMasterData = loginResponse.masters[masterKey]
    let economicRequirementData = {...economicServiceData.economic_requirement}
    let systemSettingData = {...economicServiceData.system_setting}
    let loginServiceData = loginResponse.services && loginResponse.services[service] ? loginResponse.services[service] : null
    //マスタデータ挿入（一度全データ挿入）
    const setMasterData = () => {
        let resultObject = {}
        for(let economicMasterKey in serviceMasterData){
            //option_fee_early_deposit_service_twoとoption_fee_early_deposit_service_three以外のoption_fee_を申し込み情報にセット
            if(economicMasterKey === 'option_fee_early_deposit_service_three' || economicMasterKey === 'option_fee_early_deposit_service_two' || economicMasterKey === 'option_fee_early_deposit_service' ) continue
            if(economicMasterKey.includes('option_fee_')){
                const {optionName, systemName} = setOptionName(economicMasterKey)
                if(demandCode === 'option'){
                    resultObject = {
                        ...resultObject,
                        [optionName]:serviceMasterData[economicMasterKey],
                        [systemName]:null
                    }
                }else{
                    resultObject = {
                        ...resultObject,
                        [optionName]:null,
                        [systemName]:serviceMasterData[economicMasterKey]
                    }
                }
                
             } else {
                resultObject = {
                    ...resultObject,
                    [economicMasterKey]:serviceMasterData[economicMasterKey]
                }
            }
        }
        return resultObject
    } 
    
    //前回値データ挿入(配列内の要素とoptionFeeのみ挿入)
    const setPreEconomicRequirmentsData = () => {
        let resultObject = {}
        const requireKey = [
            "payment_site",
            "early_payment_flag",
            "monthly_payment_method",
            "zeus_incentive_id"
        ]
        if(loginServiceData === null) return resultObject
        for(let requirementKey in loginServiceData.economic_requirement){
            // monthly_option_,system_usage_fee_から始まるキーと配列内に存在するキーに前回のデータを挿入
            if(requirementKey.includes('monthly_option_') 
            || requirementKey.includes('system_usage_fee_') 
            || requireKey.includes(requirementKey)){
                let value = loginServiceData.economic_requirement[requirementKey]
                resultObject = {...resultObject,[requirementKey]:value}
            }
            if((requirementKey.includes('monthly_option_') && demandCode === 'system') 
            || (requirementKey.includes('system_usage_fee_') && demandCode === 'option')){
                resultObject = {...resultObject,[requirementKey]:null}
            }
        }
        return resultObject
    }

    //srvice_code_listからサービス選択フラグを生成
    const setSelectFlag = () => {
        let resultFlag = false
        if((loginResponse.service_code_list && loginResponse.service_code_list.split('/').includes(service)) || service === 'credit'){
            resultFlag = true
        } else {
            // あと払いキャンペーン対策(キャンペーン終了するが、今後再利用も考慮してためコメントアウト対応)
            // 初回ログイン時(EntryIdが未発行)かつDBに保存されてない場合デフォルトONにする
            // const arrayInitSelectFlag = ["bank", "atobarai"]
            // if(loginResponse.entry_id === null && arrayInitSelectFlag.includes(service))  {
            //     resultFlag = true
            // }
        }
        return resultFlag
    }

    //マスタデータ挿入
    const masterData = setMasterData()
    //前回値データ挿入
    const preEconomicRequirementData = setPreEconomicRequirmentsData()
    //srvice_code_listからサービス選択フラグを生成
    const selectFlag = setSelectFlag()
    economicRequirementData = {
        ...economicRequirementData,
        ...masterData,
        ...preEconomicRequirementData,
    }
    economicRequirementData = {
        ...economicRequirementData,
        payment_site: paymentSiteChangeToPhraseHandler(economicRequirementData.payment_site)
    }
    
    systemSettingData = {
        ...systemSettingData,
        select_flag: selectFlag
    }

    if(service === 'atobarai'){
        return {economic_requirement:economicRequirementData, system_setting:systemSettingData}
    }

    if(loginServiceData !== null){
        systemSettingData = {
            ...systemSettingData,
            ...loginServiceData.system_setting,
        }
    }

    return {economic_requirement:economicRequirementData, system_setting:systemSettingData}
}

//オプション料金名(system_usage_fee,monthly_option_fee)をセット
const setOptionName = (option: string) => {
    let optionName = ""
    let systemName = ""
    switch(option){
        case "option_fee_3d_secure":
            optionName = "monthly_option_fee_3d_secure"
            systemName = "system_usage_fee_3d_secure"
            break
        case "option_fee_cvv":
            optionName = "monthly_option_fee_cvv"
            systemName = "system_usage_fee_cvv"
            break
        case "option_fee_confirmation_email":
            optionName = "monthly_option_fee_confirmation_email"
            systemName = "system_usage_fee_confirmation_email"
            break
        case "option_fee_continuous_billing":
            optionName = "monthly_option_fee_continuous_billing"
            systemName = "system_usage_fee_continuous_billing"
            break
        case "option_fee_payment_now":
            optionName = "monthly_option_fee_payment_now"
            systemName = "system_usage_fee_payment_now"
            break
        case "option_fee_customer_support":
            optionName = "monthly_option_fee_customer_support"
            systemName = "system_usage_fee_customer_support"
            break
        case "option_fee_div2_bonus":
            optionName = "monthly_option_fee_div2_bonus"
            systemName = "system_usage_fee_div2_bonus"
            break
        case "option_fee_id_password_storage":
            optionName = "monthly_option_fee_id_password_storage"
            systemName = "system_usage_fee_id_password_storage"
            break
        default:
            break
    }
    return {optionName, systemName}
}

//システム設定マスタによって経済条件の値をヌルに変更,type補完,支払いサイト（クレジット）フラグ補完
export const setEconomicFlagBySystemSetting = (systemSettingData: initSystemSettingMasterType,economicData: initEconomicSessionDataType) => {
    let economicInsert = {...economicData}
    let creditEconomicInsert = {...economicData.credit.economic_requirement}
    let bankEconomicInsert = {...economicData.bank.economic_requirement}
    let cvsEconomicInsert = {...economicData.cvs.economic_requirement}
    let atobaraiEconomicInsert = {...economicData.atobarai.economic_requirement}
    let creditSystemInsert = {...economicData.credit.system_setting}
    let bankSystemInsert = {...economicData.bank.system_setting}
    let cvsSystemInsert = {...economicData.cvs.system_setting}
    let atobaraiSystemInsert = {...economicData.atobarai.system_setting}
    if(economicData.credit.economic_requirement.payment_site === '月2回締め 月2回払い' || economicData.credit.economic_requirement.payment_site === '月3回締め 月3回払い'){
        creditEconomicInsert.early_payment_flag = true
    }
    if(!systemSettingData.credit_3d_agent_flag){
        creditSystemInsert.threed_secure = false
    }
    if(systemSettingData.credit_3d_agent_flag && !systemSettingData.credit_3d_merchant_flag){
        creditSystemInsert.threed_secure = true
    }
    if(!systemSettingData.credit_auth_agent_flag){
        creditSystemInsert.auth = false
    }
    if(systemSettingData.credit_auth_agent_flag && !systemSettingData.credit_auth_merchant_flag){
        creditSystemInsert.auth = true
    }
    if(!systemSettingData.credit_cgi_agent_flag){
        creditSystemInsert.cgi = false
    }
    if(systemSettingData.credit_cgi_agent_flag && !systemSettingData.credit_cgi_merchant_flag){
        creditSystemInsert.cgi = true
    }
    if(!systemSettingData.credit_customer_support_agent_flag){
        creditSystemInsert.customer_support = false
    }
    if(systemSettingData.credit_customer_support_agent_flag && !systemSettingData.credit_customer_support_merchant_flag){
        creditSystemInsert.customer_support = true
    }
    if(!systemSettingData.credit_cvv_agent_flag){
        creditSystemInsert.cvv = false
    }
    if(systemSettingData.credit_cvv_agent_flag && !systemSettingData.credit_cvv_merchant_flag){
        creditSystemInsert.cvv = true
    }
    if(!systemSettingData.credit_div2_bonus_agent_flag){
        creditSystemInsert.div = false
    }
    if(systemSettingData.credit_div2_bonus_agent_flag && !systemSettingData.credit_div2_bonus_merchant_flag){
        creditSystemInsert.div = true
    }
    if(!systemSettingData.credit_id_password_storage_agent_flag){
        creditSystemInsert.id_password_storage = false
    }
    if(systemSettingData.credit_id_password_storage_agent_flag && !systemSettingData.credit_id_password_storage_merchant_flag){
        creditSystemInsert.id_password_storage = true
    }
    if(!systemSettingData.credit_send_mail_agent_flag){
        creditSystemInsert.send_mail = false
    }
    if(systemSettingData.credit_send_mail_agent_flag && !systemSettingData.credit_send_mail_merchant_flag){
        creditSystemInsert.send_mail = true
    }
    if(!systemSettingData.credit_subscription_agent_flag){
        creditSystemInsert.subscription = false
    }
    if(systemSettingData.credit_subscription_agent_flag && !systemSettingData.credit_subscription_merchant_flag){
        creditSystemInsert.subscription = true
    }
    if(!systemSettingData.credit_payment_now_agent_flag){
        creditSystemInsert.payment_now = false
    }
    if(systemSettingData.credit_payment_now_agent_flag && !systemSettingData.credit_payment_now_merchant_flag){
        creditSystemInsert.payment_now = true
    }
    if(!systemSettingData.cvs_cgi_agent_flag){
        cvsSystemInsert.cgi = false
    }
    if(systemSettingData.cvs_cgi_agent_flag && !systemSettingData.cvs_cgi_merchant_flag){
        cvsSystemInsert.cgi = true
    }
    if(!systemSettingData.cvs_customer_support_agent_flag){
        cvsSystemInsert.customer_support = false
    }
    if(systemSettingData.cvs_customer_support_agent_flag && !systemSettingData.cvs_customer_support_merchant_flag){
        cvsSystemInsert.customer_support = true
    }
    if(!systemSettingData.cvs_send_mail_agent_flag){
        cvsSystemInsert.send_mail = false
    }
    if(systemSettingData.cvs_send_mail_agent_flag && !systemSettingData.cvs_send_mail_merchant_flag){
        cvsSystemInsert.send_mail = true
    }
    if(!systemSettingData.cvs_early_payment_service_agent_flag){
        cvsEconomicInsert.early_payment_flag = false
    }
    if(systemSettingData.cvs_early_payment_service_agent_flag && !systemSettingData.cvs_early_payment_service_merchant_flag){
        cvsEconomicInsert.early_payment_flag = true
    }
    if(!systemSettingData.bank_cgi_agent_flag){
        bankSystemInsert.cgi = false
    }
    if(systemSettingData.bank_cgi_agent_flag && !systemSettingData.bank_cgi_merchant_flag){
        bankSystemInsert.cgi = true
    }
    if(!systemSettingData.bank_customer_support_agent_flag){
        bankSystemInsert.customer_support = false
    }
    if(systemSettingData.bank_customer_support_agent_flag && !systemSettingData.bank_customer_support_merchant_flag){
        bankSystemInsert.customer_support = true
    }
    if(!systemSettingData.bank_send_mail_agent_flag){
        bankSystemInsert.send_mail = false
    }
    if(systemSettingData.bank_send_mail_agent_flag && !systemSettingData.bank_send_mail_merchant_flag){
        bankSystemInsert.send_mail = true
    }
    if(!systemSettingData.bank_early_payment_service_agent_flag){
        bankEconomicInsert.early_payment_flag = false
    }
    if(systemSettingData.bank_early_payment_service_agent_flag && !systemSettingData.bank_early_payment_service_merchant_flag){
        bankEconomicInsert.early_payment_flag = true
    }
    //type追加
    creditSystemInsert.type = systemSettingData.credit_payment_type
    bankSystemInsert.type = systemSettingData.bank_payment_type
    cvsSystemInsert.type = systemSettingData.cvs_payment_type
    //cvv_setting追加
    creditSystemInsert.cvv_setting = systemSettingData.credit_cvv_setting_agent
    // cgi_param追加
    if(systemSettingData.credit_cgi_agent_flag){
        creditSystemInsert.cgi_param = systemSettingData.credit_cgi_param_agent
    }
    if(systemSettingData.bank_cgi_agent_flag){
        bankSystemInsert.cgi_param = systemSettingData.bank_cgi_param_agent
    }
    if(systemSettingData.cvs_cgi_agent_flag){
        cvsSystemInsert.cgi_param = systemSettingData.cvs_cgi_param_agent
    }
    
    economicInsert = {
        ...economicInsert, 
        credit:{economic_requirement:creditEconomicInsert, system_setting:creditSystemInsert}, 
        bank:{economic_requirement:bankEconomicInsert, system_setting:bankSystemInsert},
        cvs:{economic_requirement:cvsEconomicInsert, system_setting:cvsSystemInsert},
        atobarai:{economic_requirement:atobaraiEconomicInsert, system_setting:atobaraiSystemInsert}
    }

    return economicInsert
}