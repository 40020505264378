import styled from 'styled-components';
import { fonts } from '../../../css/variables/fonts';
import { mixins } from '../../../css/variables/mixins';
import { colors } from '../../../css/variables/colors';
import AnotherTabLink from "./AnotherTabLink";
import React from 'react';

const RecomendStyle = styled.div`
    background: #e1516d;
    border-bottom: solid 1px #b7465c;
    border-right: solid 1px #cc5068; 
    color: ${colors.themeColor('text_white')};
    font-size: ${fonts.fontSizeRem('xs')};
    font-weight: 600;
    margin-top: 4px;
    letter-spacing: 1px;
    padding-left: 6px;
    padding-top: 2px;
    ${mixins.screenUnder()}{
        font-size: ${fonts.fontSizeRem('xxxs')};
    }
`

const RecomendPop = () => {
/* 追々使う機会もありそうなのでコメントアウトで対応   
    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }
*/        
    return(
        <RecomendStyle>
            おススメ！最短８営業日で導入可能！
        </RecomendStyle>
    )
}

export default RecomendPop