import { useState, useContext, useEffect, useMemo } from "react";
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api';
import { mailValidate, singlePasswordValidate } from '../validators/publicValidator'
import { useLocation } from "react-router-dom";
import { getSession, setAgentMasterInfoData, setBankAccountInfoData, setEconomicConditionMasterInfoData, setEconomicDevidedInfoData, setEconomicInfoData, setFileInfoData, setLoginInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setReviewContentMasterInfoData, setSession, setShopInfoData, setSystemSettingMasterInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod";
import { paymentSiteChangeToPhraseHandler } from "../function/paymentSiteToCode";
import { setIsLoading } from "../function/setIsLoading";
import { setSessionAllData } from "../function/setInitSessionData";
import { loginResponseDataType, loginResponseEconomicMasterAtobaraiType, loginResponseEconomicMasterBankType, loginResponseEconomicMasterCreditType, loginResponseEconomicMasterCvsType, initApiModelType, loginResponseType } from "../constance/typeModel";
import { initApiResponse, ocnkTeikiPlanLists, ToolChipDiv } from "../constance/constance";
import useNavigateByAgent from "./useNavigateByAgentHooks";
import { economicInsertHandler, entryDataSuitHandler, reviewContentInsertHandler, setEconomicFlagBySystemSetting, setServiceShowFlagHandler, systemSettingMasterSetHandler } from "../function/loginFunction";


const useLoginHooks = (agentMasterList: string[] | number[]) => {
    const {navigateByAgent} = useNavigateByAgent()
    const { state, dispatch } = useContext(Context);
    const [email, setEmail] = useState<string>(state.login.email);
    const [password, setPassword] = useState<string>(state.login.password);
    const [isClosed, setIsClosed] = useState(false)
    const location = useLocation()
    
    type loginRequestType = {
        email: string,
        password: string,
        agent_master_id_list: string[] | number[]
    }
    type errMsgType = {
        email: string,
        password: string,
    }
    const [errMsgs, setErrMsg] = useState<errMsgType>({
        email: '',
        password: '',
    })

    // 変数返却
    const EmailData = email
    const PasswordData = password
    const ErrMsgs = errMsgs
    let loginData: loginRequestType = {email:"", password:"", agent_master_id_list:[]}
    //セッションデータ抽出
	const userInfoData = getSession('user')
    const loginInfoData = getSession('login')
	const agentMasterInfoData = getSession('agent_master')
	const merchantInfoData = getSession('merchant')
	const bankAccountInfoData = getSession('bank_account')
	const representativeInfoData = getSession('representative')
	const operationInfoData = getSession('operation')
	const economicInfoData = getSession('economic_condition')
	const fileInfoData = getSession('files')
	const shopInfoData = getSession('shop')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const systemSettingMasterInfoData = getSession('system_setting_master')
    const reviewContentMasterInfoData = getSession('review_contents_master')

    // ブラウザバックを禁止する
    const browserBackBan = () =>{
        window.history.pushState(null, "", null);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, "", null)
            return
        });
    }

    //ログイン画面読み込み時処理
    useEffect(()=>{
        if(loginInfoData !== null && loginInfoData.code === 200) return
        setSessionAllData()
        //認証ページから遷移の場合のみブラウザバック禁止
        if(location.state === null || location.state.routeTo.split('/').slice(-1)[0] !== 'login' ) return
        browserBackBan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    // 経済状況マスタ情報の成型ハンドラ
    const economicConditionMasterInsertHandler = (loginResponse: loginResponseType) => {
        let economicConditionMasterData: {
            credit?: loginResponseEconomicMasterCreditType,
            cvs?: loginResponseEconomicMasterCvsType,
            bank?: loginResponseEconomicMasterBankType,
            atobarai?: loginResponseEconomicMasterAtobaraiType,
        } = {};
        
        const responseMasterData = loginResponse.response_data.masters

        const insertEconomicMaster = <T,>(service: string, masterData: T) => {
            let preEconomicConditionMasterData = {...economicConditionMasterInfoData[service]}
            for(let item in masterData){
                let value = masterData[item]
                preEconomicConditionMasterData = {
                    ...preEconomicConditionMasterData, 
                    [item]:value
                }
            }
            if("payment_site" in preEconomicConditionMasterData){
                preEconomicConditionMasterData.payment_site = paymentSiteChangeToPhraseHandler(preEconomicConditionMasterData.payment_site)
            }
            return preEconomicConditionMasterData
        }
        const creditMasterData = insertEconomicMaster('credit', responseMasterData.credit_setting_masters)
        const bankMasterData = insertEconomicMaster('bank', responseMasterData.bank_setting_masters)
        const cvsMasterData = insertEconomicMaster('cvs', responseMasterData.cvs_setting_masters)
        const masterAtobaraiData = insertEconomicMaster('atobarai', responseMasterData.atobarai_setting_masters)

        economicConditionMasterData = {
            credit: creditMasterData,
            bank: bankMasterData,
            cvs: cvsMasterData,
            atobarai: masterAtobaraiData
        }
        
        return economicConditionMasterData
    }

    // ログインに成功したらダッシュボードにページ遷移
    const LoginMethod = async (email: string, password: string) => {
        let loginResponse: initApiModelType<loginResponseDataType> = initApiResponse
        const validateMail = mailValidate(email)
        const validatePassword = singlePasswordValidate(password)
        setErrMsg({ 
            email: '', 
            password: '' 
        })

        if (!validateMail.isValidate || !validatePassword.isValidate) {
            const errorMessage = {
                email: "", 
                password: ""
            }
            errorMessage.email = validateMail.message;
            errorMessage.password = validatePassword.message;
            setErrMsg(errorMessage)
            return loginResponse
        }
        loginData = {
            "email": email,
            "password": password,
            "agent_master_id_list":agentMasterList
        }
        //API通信の間ローディング画面を表示するフラグをONにする
        setIsLoading(true,dispatch)
        loginResponse = await PostMethod<loginRequestType,loginResponseDataType>(loginData, '/login');
        return loginResponse;
    }
    //正常時処理
    const normal = (response: loginResponseType) => {
        const systemSettingData = systemSettingMasterSetHandler(response.response_data)
        const {merchantData,representativeData,operatorData,bankObj,reviewContentInsert} = entryDataSuitHandler(response.response_data)
        const economicConditionMasterData = economicConditionMasterInsertHandler(response)
        const reviewContentMasterPlusShopAddition = reviewContentInsertHandler(response.response_data,reviewContentInsert)
        let preEconomicData = economicInsertHandler(response.response_data,economicInfoData,response.response_data.masters.agent_master.demand_code)
        //経済条件マスタに項目がない場合表示しない制御をするフラグ
        const economicDividedData = setServiceShowFlagHandler(economicConditionMasterData)        
        const economicData = setEconomicFlagBySystemSetting(systemSettingData,preEconomicData)

        //ユーザリコイルセット
        setUserInfoData({
            ...userInfoData, 
            email:loginData.email,
            user_id:response.response_data.user_id, 
            entry_id:response.response_data.entry_id, 
            agent_master_id:response.response_data.agent_master_id,
            ip_flag:response.response_data.ip_flag,
	    ip_code: response.response_data.ip_code,
            is_merchant_entry: response.response_data.entry_info_merchant ? true : false
	})
        //エージェントマスターリコイルセット
        setAgentMasterInfoData({
            ...agentMasterInfoData, 
            ...response.response_data.masters.agent_master
        })
        //システム設定情報マスタリコイルセット
        setSystemSettingMasterInfoData({
            ...systemSettingMasterInfoData,
            ...systemSettingData
        })
        //ショップ情報マスタリコイルセット
        setReviewContentMasterInfoData({
            ...reviewContentMasterInfoData,
            ...response.response_data.masters.shop_addition_masters
        })
        //経済条件マスタリコイルセット    
        setEconomicConditionMasterInfoData(economicConditionMasterData)
        //ショップ情報の格納
	let shopData = {
            ...shopInfoData,
	    ...reviewContentMasterPlusShopAddition
	}
        setShopInfoData(shopData)
        //経済条件にマスタの値を挿入
        setEconomicInfoData(economicData)
        //お客様情報リコイルセット
        setMerchantInfoData({
            ...merchantInfoData,
            ...merchantData
        })
        //代表者情報リコイルセット
        setRepresentativeInfoData({
            ...representativeInfoData,
            ...representativeData
        })
        //担当者情報リコイルセット
		setOperationInfoData({ 
            ...operationInfoData,
            ...operatorData 
        })
        //口座情報リコイルセット
		setBankAccountInfoData({ 
            ...bankAccountInfoData,
            ...bankObj 
        })
        //ファイル情報リコイルセット
        setFileInfoData({
            ...fileInfoData, 
            ...response.response_data.entry_info_file
        })
        //マスタによる経済条件だしわけ情報
        setEconomicDevidedInfoData(economicDividedData)
        let loginInfoData = {
            err_message: '',
            code: response.code
        }
        
        //ログインチェックで使用
        setLoginInfoData(loginInfoData)
        //ローディング画面を非表示
        setIsLoading(false,dispatch)

        return navigateByAgent('/dashboard')
    }

    //異常時処理
    const abnormal = (response: loginResponseType) => {
        let loginData = {
            err_message: <ToolChipDiv>{response.message}</ToolChipDiv>,
            code: response.code
        }
        if(response.code === 403){
            dispatch({
                type: 'set-context-data',
                payload: {
                    auth: loginData
                }
            })
            setIsClosed(true)
        }
        //ログインチェックで使用
        setSession('login', loginData)
        //ローディング画面を非表示
        setIsLoading(false,dispatch)
        return navigateByAgent('/login')
    }

    const setPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setPassword(e.target.value)
    }

    const setEmailHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setEmail(e.target.value)
    }

    // 代理店表示
    const agentName = useMemo(()=>{
        let result = ''
        if(agentMasterList === ocnkTeikiPlanLists){
            result = 'おちゃのこネット定期購入決済'
        }
        return result
    },[agentMasterList])

    return { LoginMethod, EmailData, PasswordData, isClosed, normal, abnormal, setPasswordHandler, setEmailHandler, ErrMsgs, agentName }
}

export default useLoginHooks
