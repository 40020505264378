//\u4E00-\u9FFF\u3005-\u3007漢字
//\u30A0-\u30FF全角カナ
//\uFF61-\uFF9F半角カナ
//\u3040-\u309Fひらがな
//\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e半角記号
//\x21-\x2f\x3a-\x40\x5b-\x60\x7b-\x7e\u2010-\u203E\u3001-\u3036\uFF01-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\uFFE0-\uFFE6全角記号
// \uFF41-\uFF5A　全角アルファベット大文字	
// \uFF21-\uFF3A　全角アルファベット小文字	

import { bankDataFormatResponseDataType, sessionFileType } from "../constance/typeModel";
import { validateMessageBankInsertDataType, validateMessageMerchantInsertDataType, validateMessageShopInsertDataType, validateMessageType } from "../constance/providerTypeModel";

//バリデーションパターン
export const validationPattern = {
    emailModel : /^[a-z0-9]{1}[a-z0-9_.-]*@{1}[a-z0-9_.-]+\.{1}[a-z0-9]+$/,
    emailOnlyUsed: /^[a-z0-9_.\-@]*$/,
    password : /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\^$*.[\]{}()?"!@#%&/,><:;|_~`=+-])[0-9A-Za-z^$*.[\]{}()?"!@#%&/,><:;|_~`=+-]{12,}$/,
    passwordCantUseSymbol: /^(?=.*[^\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309F\uFF41-\uFF5A\uFF21-\uFF3AA-Za-z0-9０-９^$*.[\]{}()?"!@#%&/,><:;|_~`=+-]).*$/ ,
    fullWidth : /^[^ -~｡-ﾟ]+$/,
    halfWidth : /^[ｦ-ﾟ]+$/,
    halfWidthPlusHyphen : /^[ｦ-ﾟ-]+$/,
    int : /^[0-9]+$/,
    municipality: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309F]+$/,
    streetbunch: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309F\uFF61-\uFF9F\uFF41-\uFF5A\uFF21-\uFF3AA-Za-z0-9０-９\-‐ 　]+$/,
    streetbunchKana: /^[ｦ-ﾟ0-9\- ]+$/,
    building: /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FーA-Za-z0-9０-９ 　]+$/,
    buildingKana: /^[Ffｦ-ﾟ0-9\- ]+$/,
    representativeName : /^[\u4E00-\u9AD8\u9ADA-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309F\uFF41-\uFF5A\uFF21-\uFF3A・]+$/,
    bankSymbol : /^[^()/. \u002D]+$/,
    bankSymbolLongVowel : /^(?=.*[\uFF70]).*$/,
    // bankName, nonSmallAlphabet, nonFullWidthの３つは口座名義バリデーションの置換で使用するため他の正規表現と少し異なる
    bankName : /[A-Z0-9ｦ-ﾟ()/. \u002D]/g,
    nonSmallAlphabet : /[a-z]/g,
    nonFullWidth : /[\u30A0-\u30FF\uFF41-\uFF5A\uFF21-\uFF3A\u3040-\u309F\u4E00-\u9FFF\u3005-\u3007０-９　]/g,
    accountId : /^[A-Za-z0-9-_]+$/,
    urlHttp : /^https?:\/\//,
    urlAlphanumeric : /^[\w!?/+\-_~=;.,*&@#$%()'[\]]+$/,
    freeText : ['その他','そのた'],
    legalPersonality : ['株式会社','有限会社','合名会社','合資会社','合同会社','医療法人','医療法人社団','医療法人財団','社会医療法人','財団法人','一般財団法人','公益財団法人','社団法人','一般社団法人','公益社団法人','宗教法人','学校法人','国立大学法人','公立大学法人','社会福祉法人','更生保護法人','相互会社','特定非営利活動法人','独立行政法人','地方独立行政法人','弁護士法人','行政書士法人','司法書士法人','税理士法人','有限責任中間法人','無限責任中間法人','農事組合法人','管理組合法人','社会保険労務士法人',],
    legalPersonalityKana : ['ｶﾌﾞｼｷｶﾞｲｼｬ','ﾕｳｹﾞﾝｶﾞｲｼｬ','ｺﾞｳﾒｲｶﾞｲｼｬ','ｺﾞｳｼｶﾞｲｼｬ','ｺﾞｳﾄﾞｳｶﾞｲｼｬ','ｲﾘｮｳﾎｳｼﾞﾝ','ｲﾘｮｳﾎｳｼﾞﾝｼｬﾀﾞﾝ','ｲﾘｮｳﾎｳｼﾞﾝｻﾞｲﾀﾞﾝ','ｼｬｶｲｲﾘｮｳﾎｳｼﾞﾝ','ｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｲｯﾊﾟﾝｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｺｳｴｷｻﾞｲﾀﾞﾝﾎｳｼﾞﾝ','ｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｲｯﾊﾟﾝｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｺｳｴｷｼｬﾀﾞﾝﾎｳｼﾞﾝ','ｼｭｳｷｮｳﾎｳｼﾞﾝ','ｶﾞｯｺｳﾎｳｼﾞﾝ','ｺｸﾘﾂﾀﾞｲｶﾞｸﾎｳｼﾞﾝ','ｺｳﾘﾂﾀﾞｲｶﾞｸﾎｳｼﾞﾝ','ｼｬｶｲﾌｸｼﾎｳｼﾞﾝ','ｺｳｾｲﾎｺﾞﾎｳｼﾞﾝ','ｿｳｺﾞｶﾞｲｼｬ','ﾄｸﾃｲﾋｴｲﾘｶﾂﾄﾞｳﾎｳｼﾞﾝ','ﾄﾞｸﾘﾂｷﾞｮｳｾｲﾎｳｼﾞﾝ','ﾁﾎｳﾄﾞｸﾘﾂｷﾞｮｳｾｲﾎｳｼﾞﾝ','ﾍﾞﾝｺﾞｼﾎｳｼﾞﾝ','ｷﾞｮｳｾｲｼｮｼﾎｳｼﾞﾝ','ｼﾎｳｼｮｼﾎｳｼﾞﾝ','ｾﾞｲﾘｼﾎｳｼﾞﾝ','ﾕｳｹﾞﾝｾｷﾆﾝﾁｭｳｶﾝﾎｳｼﾞﾝ','ﾑｹﾞﾝｾｷﾆﾝﾁｭｳｶﾝﾎｳｼﾞﾝ','ﾉｳｼﾞｸﾐｱｲﾎｳｼﾞﾝ','ｶﾝﾘｸﾐｱｲﾎｳｼﾞﾝ','ｼｬｶｲﾎｹﾝﾛｳﾑｼﾎｳｼﾞﾝ',],
    legalPersonalityKanaCount : ['ｶﾞ','ｷﾞ','ｸﾞ','ｹﾞ','ｺﾞ','ｻﾞ','ｼﾞ','ｽﾞ','ｾﾞ','ｿﾞ','ﾀﾞ','ﾁﾞ','ﾂﾞ','ﾃﾞ','ﾄﾞ','ﾊﾞ','ﾋﾞ','ﾌﾞ','ﾍﾞ','ﾎﾞ','ﾊﾟ','ﾋﾟ','ﾌﾟ','ﾍﾟ','ﾎﾟ',],
    shopNameAlphabet : /^[A-Z0-9.\- ]+$/,
    shopNameKana : /^[ｦ-ﾟ ]+$/,
    shopName : /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FA-Za-z0-9 　\-ー.]+$/,
    shopWebsiteCantUseSymbol: /^(?=.*[￥\\¥！!＃#＄$％%（(）)～~｜|\-＾^＠@[：:\]./`｛{　 *＊｝}？?＿_＋+＝=]).*$/,
    onlyFullWidth: /^(?=.*[A-Za-z0-9ｦ-ﾟ -/:-@[-´{-~]).*$/ ,
    onlyStringFullWidth: /^(?=.*[A-Za-zｦ-ﾟ]).*$/ ,
    //スペース、二点リーダー、※、シングルクォート、ダブルクォートをバリデーションにかける
    atobaraiCantUseSymbol: /^(?=.*[　‥※’”]).*$/ ,
    atobarai : /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FFｦ-ﾟ\u3040-\u309FA-Za-z0-9０-９]+$/,
    file : ['pdf','jpeg','jpg','png','PDF','JPEG','JPG','PNG'],
}
// バリデーション戻り値
const resValidateModel: ({isValidate: boolean, message: string}) = {
    isValidate: false,
    message: '',
}

// メールバリデーション
export const mailValidate = (mail: string) : {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const pattern : RegExp = validationPattern.emailModel;
    // ブランクバリデーション
    if (mail === '') {
        resValidate.message = 'メールアドレスを入力してください';
        return resValidate
    }
    // 正規表現バリデーション
    if (!pattern.test(mail)) {
        resValidate.message = '正しいメールアドレスを入力してください';
        return resValidate
    }
    resValidate.isValidate = true;
    return resValidate
}

// パスワード再入力のバリデーション
export const confirmPasswordValidate = (password: string, confirmPassword: string): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const passwordPattern : RegExp = validationPattern.password;
    const passwordCantUseSymbolPattern : RegExp = validationPattern.passwordCantUseSymbol
    // ブランクバリデーション
    if (password === '' || confirmPassword === '') {
        resValidate.message = '両方のパスワードを入力してください';
        return resValidate
    }
    // 再入力されたパスワードと一致するか
    if (password !== confirmPassword) {
        resValidate.message = '再入力されたパスワードと一致しません'
        return resValidate
    }
    // 使用不可正規表現バリデーション
    if(passwordCantUseSymbolPattern.test(password)){
        resValidate.message = '使用できない記号が含まれています。';
        return resValidate
    }
    // パスワード正規表現バリデーション
    if (!passwordPattern.test(password)) {
        resValidate.message = '半角英数字,英字の大文字,記号を含む１２文字以上入力してください';
        return resValidate
    }
    resValidate.isValidate = true;
    return resValidate
}

export const singlePasswordValidate = (password: string) : {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const pattern : RegExp = validationPattern.password;
    // ブランクバリデーション
    if (password === '') {
        resValidate.message = 'パスワードを入力してください';
        return resValidate
    }
    // 正規表現バリデーション
    if (!pattern.test(password)) {
        resValidate.message = '半角英数字,英字の大文字,記号を含む１２文字以上入力してください';
        return resValidate
    }
    resValidate.isValidate = true;
    return resValidate
}

//ここから下Entry用バリデーション
//字数制限ぴったり
const justWordCountValidate = (data: string,number: number) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    // 文字数バリデーション
    if(data.length !== number){
        message = `${number}文字で入力してください`
        return message
    }
    return message
}
//字数制限ぴったり(桁バージョン)
const justCountKetaValidate = (data: string,number: number) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    // 桁数バリデーション
    if(data.length !== number){
        message = `${number}桁で入力してください`
        return message
    }
    return message
}
//字数制限上限
const UpperLimitCountValidate = (data: string,number: number) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    // 文字数バリデーション
    if(data.length > number){
        message = `${number}文字以内で入力してください`
        return message
    }
    return message
}
//数字バリデーション
export const intValidate = (data: string | number) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.int
    // 正規表現バリデーション
    if(typeof data !== 'number' && !pattern.test(data)){
        message = '半角数字で入力してください'
        return message
    }
    return message
}
//emailバリデーション
const emailValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.emailModel
    const disablePattern : RegExp = validationPattern.emailOnlyUsed
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '正しいメールアドレスを入力してください。'
    }
    // 使用不可文字バリデーション
    if(!disablePattern.test(data)){
        message = '利用できない文字が含まれています。'
    }
    return message
}
//代表者名バリデーション
const representativeNameValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.representativeName
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '数字、および、「・」（中黒点）以外の記号は使用できません。'
    }
    return message
}
//口座名義カナバリデーション(一旦全角カナ英字を許容して、後で変換)
const bankNameValidate = (data: string) :  string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    //口座名義の正しい正規表現
    const bankNamePattern : RegExp = validationPattern.bankName
    //小文字英字が存在するか確認する正規表現
    const nonSmallAlphabetPattern : RegExp = validationPattern.nonSmallAlphabet
    //全角が入っているか確認する正規表現
    const nonFullWidthPattern : RegExp = validationPattern.nonFullWidth
    //口座名義の記号のみの正規表現
    const bankSymbolPattern : RegExp = validationPattern.bankSymbol
    //記号の正規表現で\FF70(ｰ)がはじけないため、単独でバリデーションをかける
    const bankSymbolLongVowelPattern : RegExp = validationPattern.bankSymbolLongVowel
    //口座名義の正しい正規表現・
    const symbolCheckData = data.replace(nonSmallAlphabetPattern,'').replace(nonFullWidthPattern, '').replace(bankNamePattern, '')
    if(nonSmallAlphabetPattern.test(data)){
        message = '英字は大文字で入力してください。'
    }
    if(nonFullWidthPattern.test(data)){
        message = '・半角カナ・半角数字・半角英字（大文字）・半角スペース・半角記号（下記※参照）\n・長音「ー」は半角ハイフン「‐」で入力してください。'
    }
    if(bankSymbolLongVowelPattern.test(data)){
        message = '・半角カナ・半角数字・半角英字（大文字）・半角スペース・半角記号（下記※参照）\n・長音「ー」は半角ハイフン「‐」で入力してください。'
    }
    if(symbolCheckData !== "" && bankSymbolPattern.test(symbolCheckData)){
        message = '使用可能な記号は「丸括弧（）、スラッシュ/、ハイフン-、.ピリオド. 」のみです。'
    }
    return message
}
//URL型バリデーション
const urlHttpValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.urlHttp
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '「http://」または「https://」から入力してください。'
    }
    return message
}
//URL文字バリデーション
const urlAlphanumericValidate = (data: string): string => {
    let message : string = '';
    if(!data.includes('http://') && !data.includes('https://')) return message
    data = data.replace('http://','').replace('https://','')
     // ブランクの場合リターン
    if(data === '') return message
    const pattern = validationPattern.urlAlphanumeric
    // // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '半角英数・半角記号で入力してください。'
    }
    return message
}
//shop_addition_product_content_freetextバリデーション
const freeTextValidate = (data: string) : string => {
    let message : string = '';
    let checkFlag : boolean = false;
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : string[] = validationPattern.freeText
    // その他が入っていないか確認
    pattern.forEach((item)=>{
        if(data.includes(item)){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '具体的な商品内容を入力してください'
        return message
    }
    return message
}
//法人格バリデーション
const legalPersonalityValidate = (data: string) : string => {
    let message : string = '';
    let checkFlag : boolean = false;
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : string[] = validationPattern.legalPersonality
    // 法人格が入っていないか確認
    pattern.forEach((item)=>{
        if(data.includes(item)){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '法人格は登録できませんので、削除してください。'
        return message
    }
    return message
}
//法人格カナバリデーション
const legalPersonalityKanaValidate = (data: string) : string => {
    let message : string = '';
    let checkFlag : boolean = false;
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : string[] = validationPattern.legalPersonalityKana
    // 法人格が入っていないか確認
    pattern.forEach((item)=>{
        if(data.indexOf(item) !== -1){
            checkFlag = true;
        }
    })
    if(checkFlag){
        message = '法人格は登録できませんので、削除してください。'
        return message
    }
    return message
}
//半角カナバリデーション
const halfWidthKanaValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    // const pattern = /^[ -~｡-ﾟ]+$/　記号あり
    const pattern : RegExp = validationPattern.halfWidth
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '半角ｶﾅで入力してください。'
        return message
    }
    return message
}
//半角カナ+ハイフンバリデーション
const halfWidthKanaPlusHyphenValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.halfWidthPlusHyphen
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '半角ｶﾅで入力してください。'
        return message
    }
    return message
}
//全角のみバリデーション
const fullWidthValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    // const pattern = /^[\u4E00-\u9FFF\u3005-\u3007\u30A0-\u30FF\u3040-\u309FA-Za-z0-9０-９]+$/
    const pattern : RegExp = validationPattern.fullWidth
    if(!pattern.test(data)){
        message = '全角以外の文字が含まれています'
        return message
    }
    return message
}
//ショップwebsite名で使用できない記号
const shopWebsiteCantUseSymbolValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.shopWebsiteCantUseSymbol
    // 正規表現バリデーション
    if(pattern.test(data)){
        message = '利用できない文字が含まれています。'
        return message
    }
    return message
}
//ショップwebsite名で使用できない記号
const onlyFullWidthValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.onlyFullWidth
    // 正規表現バリデーション
    if(pattern.test(data)){
        message = '全角で入力してください。'
        return message
    }
    return message
}
//加盟店市区町村
const municiparityValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.municipality
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店町名番地
const streetbunchValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.streetbunch
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店建物名
const buildingValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.building
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店町名番地カナ
const streetbunchKanaValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.streetbunchKana
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//加盟店建物名カナ
const buildingKanaValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.buildingKana
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '利用できない文字が含まれています'
        return message
    }
    return message
}
//ショップ英字バリデーション
const shopNameAlphabetValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.shopNameAlphabet
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//ショップフリガナバリデーション
const shopNameKanaValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.shopNameKana
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//ショップ名バリデーション
const shopNameValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    const pattern : RegExp = validationPattern.shopName
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = 'ご利用いただけない文字が含まれています。'
        return message
    }
    return message
}
//顧客通知店舗名バリデーション
const atobaraiValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    //\u4E00-\u9FFF\u3005-\u3007漢字
    //\u30A0-\u30FFｦ-ﾟカナ
    //\u3040-\u309Fひらがな
    const pattern : RegExp = validationPattern.atobarai
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '下記の規則に沿って入力してください'
        return message
    }
    return message
}
//顧客通知店舗名で使用できない記号
const atobaraiCantUseSymbolValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.atobaraiCantUseSymbol
    // 正規表現バリデーション
    if(pattern.test(data)){
        message = '『 』（スペース）,『‥』（二点リーダー）,『※』（米印）,『\'』(分),『"』（秒）は使えません。'
        return message
    }
    return message
}
//顧客通知店舗名で使用できない記号
const accountIdValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.accountId
    // 正規表現バリデーション
    if(!pattern.test(data)){
        message = '半角英数で入力してください。'
        return message
    }
    return message
}

//ファイルバリデーション
const fileValidate = (data: sessionFileType[]) : string => {
    let message : string = '';
    // ファイルがない場合リターン
    if(data.length === 0) return message
    const fileTypeArray : string[] = validationPattern.file
    //fileの拡張子が'png','jpg','jpeg','pdf'以外の場合エラーメッセージ
    data.forEach((fileData: sessionFileType) => {
        if(!fileTypeArray.includes(fileData.file_name_origin.split('.')[1])) message = 'png,jpg,jpeg,pdf以外は登録できません'
    })
    return message
}

//銀行名プルダウン
const bankPulldownValidate = (data: string,validateData: {[key: string]: {bank_name: string}}) : string => {
    let message : string = '';
    let existFlag : boolean = false
    // ブランクの場合リターン
    if(data === "") return message
    // 存在しない銀行名の場合弾く
    for(let bankCode in validateData){
        if(validateData[bankCode].bank_name === data) existFlag = true
    }
    if(!existFlag) message = "正しい銀行名を入力・選択してください"
    return message
}

//銀行支店名プルダウン
const bankBranchPulldownValidate = (data: {bank_account_bank_name: string,bank_account_bank_branch: string},validateData: bankDataFormatResponseDataType) : string => {
    let message : string = '';
    let bankExitFlag : boolean = false
    let bankCodeData : string = ""
    let branchExistFlag : boolean = false
    let bankData : string = data.bank_account_bank_name
    let branchData : string = data.bank_account_bank_branch
    // ブランクの場合リターン
    if(branchData === "") return message
    //銀行名が存在しない場合はリターン
    for(let bankCode in validateData){
        if(validateData[bankCode].bank_name === bankData){
            bankExitFlag = true
            bankCodeData = bankCode
        } 
    }
    if(!bankExitFlag) return  message
    //銀行支店名が存在するか確認
    const bankBranchObj = validateData[bankCodeData].bank_branch
    for(let bankCode in bankBranchObj){
        if(bankBranchObj[bankCode].branch_name === branchData){
            branchExistFlag = true
        }
    }
    if(!branchExistFlag) message = "正しい支店名を入力・選択してください"
    return message
}

//フリーダイヤルバリデート
const freePhoneValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    // フリーダイアルで始まるか確認
    if(data.includes("0120")) message = 'フリーダイヤルは入力できません' 
    return message
}
//半角拒否バリデーション
const onlyStringFullWidthValidate = (data: string) : string => {
    let message : string = '';
    // ブランクの場合リターン
    if(data === '') return message
    const pattern : RegExp = validationPattern.onlyStringFullWidth
    // 正規表現バリデーション
    if(pattern.test(data)){
        message = '全角で入力してください。'
    }
    return message
}
// 料金比較バリデーション
const comparePriceValidate = (shopInfoData: {shop_addition_price_range_min: string | number | null, shop_addition_price_range_max: string | number | null}) : string => {
    let message : string = ''
    const minPrice : string | number | null = shopInfoData.shop_addition_price_range_min
    const maxPrice : string | number | null = shopInfoData.shop_addition_price_range_max
    // ブランクの場合リターン
    if((minPrice === null || maxPrice === null) || (minPrice === "" || maxPrice === "") || (intValidate(maxPrice) !== "" || intValidate(minPrice) !== "")) return message = ''
    if(maxPrice < minPrice) message = "商品の最小値が最大値を上回っています。"
    return message
}


//空欄バリデーション(ファイル用の部分で型ガードできずエラーが出るためanyを設置)
export const blankValidate = (state: {[key: string]: any},validateData: validateMessageMerchantInsertDataType | validateMessageBankInsertDataType| validateMessageShopInsertDataType,path: string) => {
    const keys = Object.keys(state)
    let propertyArray: string[] = [];
    let tempData : {[key: string]:validateMessageType}= {};
    let shop_addition_offer_place : validateMessageType={isValidate:true, message:''};
    let shop_addition_rental_validate:validateMessageType={isValidate:true, message:''};
    let blankData :validateMessageMerchantInsertDataType | validateMessageBankInsertDataType| validateMessageShopInsertDataType = {};
    //空欄バリデーションをかけない項目の配列
    const nonBlankValidateArray : string[] = ['shop_addition_shop_website_id','shop_addition_shop_website_password','shop_addition_rental_product_url','merchant_website_url','license']
    //加盟店住所ビルに入力があった場合カナに空欄バリデーションを、住所ビルカナに入力があった場合住所ビルに空欄バリデーションをかける
    if("merchant_address_building" in state && state.merchant_address_building === "") nonBlankValidateArray.push('merchant_address_building_kana')
    if("merchant_address_building_kana" in state && state.merchant_address_building_kana === "") nonBlankValidateArray.push('merchant_address_building')
    //該当ページの空欄バリデーション
    keys.forEach((key) => {
        //空欄バリデーションをかけない項目または値がnullの場合リターン
        if(nonBlankValidateArray.includes(key)) return
        if(state[key] === null) return propertyArray.push(key)
        //ファイル以外
        if(typeof(state[key]) === 'string' && state[key] === '') propertyArray.push(key)
        //ファイル用
        if(state[key].length === 0) propertyArray.push(key)
        //ショップ同意項目セレクトボックス
        key === "shop_addition_non_infringing_product_presence_absence_flag" && state[key] === false && propertyArray.push(key)
        key === "shop_addition_available_product_flag" && state[key] === false && propertyArray.push(key)
    })
    propertyArray.forEach((key) => {
        //年商の値がnullの場合に空欄判定する
        tempData = {...tempData, [key]:{isValidate:false, message:'必須項目です'}}
    })
    //レンタル規約かファイルのどちらか入力必須
    if("shop_addition_rental_validate" in state && state.shop_addition_rental_validate === false){
        shop_addition_rental_validate = {isValidate:false, message:'URLもしくはファイルのどちらかは必須です'}
    }
    //複数チェックボックスのバリデーション
    if("shop_addition_offer_place" in state && state.shop_addition_offer_place === false){
        shop_addition_offer_place = {isValidate:false, message:'１つ以上選択必須です'}
    }
    blankData = {...validateData, ...tempData}
    if(path === 'shop'){
        blankData = {...blankData, shop_addition_offer_place: shop_addition_offer_place, shop_addition_rental_validate: shop_addition_rental_validate}
    }
    return {blankData}
}

//申込情報バリデーション関数
export const entryValidator = <T,>(validate: string[],data: T,number: number) => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    if(typeof data !== "string") return resValidate
    validate.forEach((value: string) => {
        switch(value){
            case 'JustCount':
                const justCountMessage : string = justWordCountValidate(data,number)
                if(justCountMessage !== '') resValidate.message = justCountMessage
                break;
            case 'JustCountKeta':
                const justCountKetaMessage : string = justCountKetaValidate(data,number)
                if(justCountKetaMessage !== '') resValidate.message = justCountKetaMessage
                break;
            case 'UpperLimitCount':
                const upperLimitCountMessage : string = UpperLimitCountValidate(data,number)
                if(upperLimitCountMessage !== '') resValidate.message = upperLimitCountMessage
                break;
            case 'Int':
                const intMessage : string = intValidate(data)
                if(intMessage !== '') resValidate.message = intMessage
                break;
            case 'Email':
                const emailMessage : string = emailValidate(data)
                if(emailMessage !== '') resValidate.message = emailMessage
                break;
            case 'RepresentativeName':
                const representativeNameMessage : string = representativeNameValidate(data)
                if(representativeNameMessage !== '') resValidate.message = representativeNameMessage
                break;
            case 'BankName':
                const bankNameMessage : JSX.Element | string = bankNameValidate(data)
                if(bankNameMessage !== '') resValidate.message = bankNameMessage
                break;
            case 'URLHttp':
                const urlHttpMessage : string = urlHttpValidate(data)
                if(urlHttpMessage !== '') resValidate.message = urlHttpMessage
                break;
            case 'URLAlphanumeric':
                const urlAlphanumericMessage : string = urlAlphanumericValidate(data)
                if(urlAlphanumericMessage !== '') resValidate.message = urlAlphanumericMessage
                break;
            case 'FreeText':
                const freeTextMessage : string = freeTextValidate(data)
                if(freeTextMessage !== '') resValidate.message = freeTextMessage
                break;
            case 'LegalPersonality':
                const legalPersonalityMessage : string = legalPersonalityValidate(data)
                if(legalPersonalityMessage !== '') resValidate.message = legalPersonalityMessage
                break;
            case 'LegalPersonalityKana':
                const legalPersonalityKanaMessage : string = legalPersonalityKanaValidate(data)
                if(legalPersonalityKanaMessage !== '') resValidate.message = legalPersonalityKanaMessage
                break;
            case 'FullWidth':
                const fullWidthMessage : string = fullWidthValidate(data)
                if(fullWidthMessage !== '') resValidate.message = fullWidthMessage
                break;
            case 'HalfWidthKana':
                const halfWidthKanaMessage : string = halfWidthKanaValidate(data)
                if(halfWidthKanaMessage !== '') resValidate.message = halfWidthKanaMessage
                break;
            case 'HalfWidthKanaPlusHyphen':
                const halfWidthKanaPlusHyphenMessage : string = halfWidthKanaPlusHyphenValidate(data)
                if(halfWidthKanaPlusHyphenMessage !== '') resValidate.message = halfWidthKanaPlusHyphenMessage
                break;
            case 'ShopNameAlphabet':
                const shopNameAlphabetMessage : string = shopNameAlphabetValidate(data)
                if(shopNameAlphabetMessage !== '') resValidate.message = shopNameAlphabetMessage
                break;
            case 'ShopNameKana':
                const shopNameKanaMessage : string = shopNameKanaValidate(data)
                if(shopNameKanaMessage !== '') resValidate.message = shopNameKanaMessage
                break;
            case 'ShopName':
                const shopNameMessage : string = shopNameValidate(data)
                if(shopNameMessage !== '') resValidate.message = shopNameMessage
                break;
            case 'Atobarai':
                const atobaraiMessage : string = atobaraiValidate(data)
                if(atobaraiMessage !== '') resValidate.message = atobaraiMessage
                break;
            case 'FreePhone':
                const freePhoneMessage : string = freePhoneValidate(data)
                if(freePhoneMessage !== '') resValidate.message = freePhoneMessage
                break;
            case 'Municiparity':
                const municiparityMessage : string = municiparityValidate(data)
                if(municiparityMessage !== '') resValidate.message = municiparityMessage
                break;
            case 'Streetbunch':
                const streetbunchMessage : string = streetbunchValidate(data)
                if(streetbunchMessage !== '') resValidate.message = streetbunchMessage
                break;
            case 'Building':
                const buildingMessage : string = buildingValidate(data)
                if(buildingMessage !== '') resValidate.message = buildingMessage
                break;
            case 'StreetbunchKana':
                const streetbunchKanaMessage : string = streetbunchKanaValidate(data)
                if(streetbunchKanaMessage !== '') resValidate.message = streetbunchKanaMessage
                break;
            case 'BuildingKana':
                const buildingKanaMessage : string = buildingKanaValidate(data)
                if(buildingKanaMessage !== '') resValidate.message = buildingKanaMessage
                break;
            case 'ShopWebsiteCantUseSymbol':
                const ShopWebsiteCantUseSymbolMessage : string = shopWebsiteCantUseSymbolValidate(data)
                if(ShopWebsiteCantUseSymbolMessage !== '') resValidate.message = ShopWebsiteCantUseSymbolMessage
                break;
            case 'OnlyFullWidth':
                const onlyFullWidthMessage : string = onlyFullWidthValidate(data)
                if(onlyFullWidthMessage !== '') resValidate.message = onlyFullWidthMessage
                break;
            case 'AtobaraiCantUseSymbol':
                const atobaraiCantUseSymbolMessage : string = atobaraiCantUseSymbolValidate(data)
                if(atobaraiCantUseSymbolMessage !== '') resValidate.message = atobaraiCantUseSymbolMessage
                break;
            case 'AccountId':
                const accountIdMessage : string = accountIdValidate(data)
                if(accountIdMessage !== '') resValidate.message = accountIdMessage
                break;
            case 'OnlyStringFullWidth':
                const onlyStringFullWidthMessage : string = onlyStringFullWidthValidate(data)
                if(onlyStringFullWidthMessage !== '') resValidate.message = onlyStringFullWidthMessage
                break;
            default:
                break;
        }
    })
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}
//ファイルバリデーター
export const fileValidator = (data: sessionFileType[]): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const fileMessage : string = fileValidate(data)
    if(fileMessage !== '') resValidate.message = fileMessage
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}
//銀行名バリデーター
export const bankNameValidator = (data: string, validateData: {[key: string]: {bank_name: string}}): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const bankPulldownMessage : string = bankPulldownValidate(data,validateData)
    if(bankPulldownMessage !== '') resValidate.message = bankPulldownMessage
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}
//銀行支店名バリデーター
export const bankNameBranchValidator = (data: {bank_account_bank_name: string,bank_account_bank_branch: string}, validateData: bankDataFormatResponseDataType): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const bankBranchPulldownMessage : string = bankBranchPulldownValidate(data,validateData)
    if(bankBranchPulldownMessage !== '') resValidate.message = bankBranchPulldownMessage
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}
//商品価格帯の最大・最小値比較バリデーター
export const comparePriceValidator = (data: {shop_addition_price_range_min: string | number | null, shop_addition_price_range_max: string | number | null}): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const comparePriceMessage : string = comparePriceValidate(data)
    if(comparePriceMessage !== '') resValidate.message = comparePriceMessage
    if(resValidate.message === '') resValidate.isValidate = true;
    return resValidate
}

// 不備連携部分のバリデーション
export const deficiencyNameValidate = (name: string, min : number, max : number): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}

    if(name === '' ) {
        resValidate.message = "お問い合わせ名を入力してください"
        return resValidate;
    }

    if(name.length <= min || name.length >= max) {
        resValidate.message = `${min}文字以上${max}文字以内で入力してください`
        return resValidate;
    }

    resValidate.isValidate = true;
    return resValidate
}

// おちゃ定期リダイレクト時パスワードのバリデーション
export const webEntryPasswordValidate = (password: string): {isValidate: boolean, message: string} => {
    let resValidate : {isValidate: boolean, message: string} = {...resValidateModel}
    const passwordPattern : RegExp = validationPattern.password;
    const passwordCantUseSymbolPattern : RegExp = validationPattern.passwordCantUseSymbol
    if(password === "") return resValidate
    // 使用不可正規表現バリデーション
    if(passwordCantUseSymbolPattern.test(password)){
        resValidate.message = '使用できない記号が含まれています。';
        return resValidate
    }
    // パスワード正規表現バリデーション
    if (!passwordPattern.test(password)) {
        resValidate.message = '半角英数字,英字の大文字,記号を含む１２文字以上入力してください';
        return resValidate
    }
    resValidate.isValidate = true;
    return resValidate
}
