import { useRef, useState, useMemo, useContext, useCallback, DragEvent } from 'react';
import { getSession, setFileInfoData } from '../sessionStorage/sessionStorageMethod';
import { initFileSessionData } from '../constance/sessionData';
import { Context } from '../stores/Provider';
import { validationPattern } from '../validators/publicValidator';
import { PostMethod } from '../api/Api';
import { setIsLoading } from '../function/setIsLoading';
import { InquiryFileItem } from '../components/ui-parts/ocnk';
import { logoutAbnormalFunction } from '../function/logoutAbnormalFunction';
import { initApiModelType, initFileSessionDataType, fileRequestType, fileResponseType, fileDeleteRequestType, sessionFileType } from '../constance/typeModel';
import { initApiResponse } from '../constance/constance';

const useFileInputHooks = ({
    maxsize,
    id,
    multiple,
    page,
    accept,
    navigateByAgent
}: {
    maxsize: number,
    id: string,
    multiple?: boolean,
    page: string,
    accept: string[],
    navigateByAgent: Function
}) => {
    const {state, dispatch} = useContext(Context)
    const userInfoData = getSession('user')
    const inputFileRef = useRef<HTMLInputElement>(null);

    //コンテキスト抽出
    const fileContextState = useMemo(()=>{
        if(state.entry.files === null || state.entry.files === undefined || state.entry.files === initFileSessionData) return getSession('files')
        return state.entry.files
    },[state.entry.files])

    const acceptText = useMemo(()=>{
        let acceptText: string = ""
        acceptText = accept.join(',')
        return acceptText
    },[accept])

    const setFileDataHandler = (data: initFileSessionDataType) => {
        setFileInfoData(data)
        dispatch({
            type : "set-entry-files",
            payload : data

        })
    }
    const [fileState, setFileState] = useState(fileContextState[id])
    const [dragOverFlg, setDragOverFlg] = useState(false);
    
    const maxSizeByte = 1024 * 1024 * maxsize;
    const newArrayFileData = useMemo(()=>{
        const newArrayFileData: sessionFileType[] | [] = fileContextState[id].length !== 0 ? Object.values(fileContextState[id]) : []
        return newArrayFileData
    },[fileContextState,id])

    const DragOverHandler = (e: DragEvent ) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(true);
    }
    const DragLeaveHandler = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(false);
    }
    const FileDropHandler = (e: DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setDragOverFlg(false);
        var files = e.dataTransfer.files;
        if(inputFileRef.current === null) return
        inputFileRef.current.files = files;
        LoadFile(e);
    }
    const LoadFileHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        LoadFile(e);
        //同じファイルを登録->消去->登録ができないバグを解消する
        setTimeout(()=>setNullValue(e), 1000)
    }

    const setNullValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e === undefined || e === null || e.target === undefined) return
        e.target.value = ""
    }
      
    const DeteleFileHandler = useCallback(async (index: number) => {
        let response: initApiModelType<undefined> = initApiResponse
        const newFiles = newArrayFileData;
        const postData = {
            file_method:"delete",
            entry_id: userInfoData.entry_id,
            files:{
                ...initFileSessionData,
                [id] : [
                    { file_id: newFiles[index].file_id }
                ]
            }
        }
        //ローディング開始
        setIsLoading(true,dispatch)
        response = await PostMethod<fileDeleteRequestType,undefined>(postData, '/file_data')
        //エラー時ハンドラ
        if(response.code !== 200){
            if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
            return navigateByAgent('/system_error')
        }
        newFiles.splice(index, 1)
        setFileDataHandler({ ...fileContextState, [id]:newFiles })
        setFileState(newFiles)
        //ローディング終了
        setIsLoading(false,dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, fileContextState, id, navigateByAgent, newArrayFileData, userInfoData.entry_id])

    const thumbs = useMemo(() => {
        const thumb = newArrayFileData.map((file: sessionFileType, index: number) => (
            <InquiryFileItem key={index} file={file} deleteFile={()=>DeteleFileHandler(index)} upload entry />
        ))
        return thumb
    }, [DeteleFileHandler,newArrayFileData]);

    /**
     * ファイルをロード
     */   
    const LoadFile = async (e: React.ChangeEvent<HTMLInputElement> | DragEvent) => {
        if(inputFileRef.current === null) return
        let insertFileData = fileState;
        let maxFileNumber = 0
        let count = 0;
        const inputFiles = inputFileRef.current.files;
        let sumFileSize = -1

        if(inputFiles === null) return

        //複数ファイルバリデーション
        if (!multiple) {
            if (Object.entries(fileContextState[id]).length > 0 || inputFiles.length > 1) {
                alert('アップロードできるファイルは1つだけです。');
                inputFileRef.current.value = '';
                return false;
            }
        }
        
        //ファイルサイズ計算＋ファイル連番最大値取得（API通信前のファイル）
        insertFileData.forEach((file: sessionFileType) => {
            //ファイルサイズ計算
            sumFileSize += file.file_size
            //ファイル連番最大値取得
            let compareNumber = file.file_name ? Number(file.file_name.split('.')[0].split('_').pop()) : 0
            if(compareNumber > maxFileNumber) maxFileNumber = compareNumber
        })

        //ファイルを一つずつ登録
        await Promise.all(Object.entries(inputFiles).map(async (fileArray: (string | File)[]) => {
            let filePromise = new Promise((resolve)=>{
                let file = fileArray[1]
                if(typeof file === 'string') return
                sumFileSize += file.size
                //ファイルの合計が30MB以上か確認
                if (sumFileSize > maxSizeByte) {
                    alert(`「アップロードファイルのサイズ上限${maxsize}MBを超えています。ファイルサイズを小さくして再度アップロードしてください。`);
                    count = count + 1
                    sumFileSize -= file.size
                    return
                }
                var fr = new FileReader();
                fr.onload = async(e) => {
                    if(typeof file === 'string') return
                    
                    //ファイル拡張子バリデーション
                    if(!validationPattern.file.includes(file.name.split('.')[1])){
                        let message = 'png,jpg,jpeg,pdf以外は登録できません'
                        dispatch({
                            type: `set-validate-${page}`,
                            payload: {
                                ...state.validateMessages[page],
                                [id]:{
                                    message:message
                                }
                            }
                        })
                        return setIsLoading(false, dispatch) 
                    } else {
                        dispatch({
                            type: `set-validate-${page}`,
                            payload: {
                                ...state.validateMessages[page],
                                [id]:{
                                    message:""
                                }
                            }
                        })
                    }
                    
                    maxFileNumber++
                    const fileData = (e.target !== null && e.target.result !== "" ) ? ((e.target.result !== null && typeof e.target.result === 'string' ) && e.target.result.split('base64,')[1] ) : ""
                    const tempFileData = { file_name_origin: file.name, file_size: file.size, file_data: fileData }
                    resolve(tempFileData)
                }
                fr.readAsDataURL(file);
            })
            return filePromise
        })).then(async(responseArray)=>{
            let response: initApiModelType<fileResponseType> = initApiResponse
            const postData = {
                entry_id : userInfoData.entry_id,
                file_method : "register",
                files : {
                    ...initFileSessionData,
                    [id] : responseArray
                }
            }
            //ローディング開始
            setIsLoading(true, dispatch)
            response = await PostMethod<fileRequestType,fileResponseType>(postData, '/file_data') 
            //ローディング終了
            setIsLoading(false, dispatch)
            // ファイル登録失敗時の挙動
            if(response.code !== 200 || response.response_data === undefined){
                if(response.code === 401) return logoutAbnormalFunction(response,dispatch,navigateByAgent,state.agentPath)
                return navigateByAgent('/system_error')
            } 
            // ファイル登録成功時の挙動
            insertFileData = insertFileData.concat(response.response_data.files)
            setFileState(insertFileData)
            setFileDataHandler({ ...fileContextState, [id]: insertFileData });
        })
    }
    return {dragOverFlg, setDragOverFlg, thumbs, DragOverHandler, DragLeaveHandler, FileDropHandler, LoadFileHandler, inputFileRef, fileContextState, acceptText}
}

export default useFileInputHooks