import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { GetMethod } from "../api/Api"
import { initApiResponse, initCategoryMediumDevided, initCategoryResponse } from "../constance/constance"
import { initFileSessionData, initReviewContentsDivisionSessionData, initShopSessionData } from "../constance/sessionData"
import { itemValidators } from "../validators/validationPattern"
import { getSession, setReviewContentsDivisionInfoData, setShopInfoData } from "../sessionStorage/sessionStorageMethod"
import { Context } from "../stores/Provider"
import { setIsLoading } from "../function/setIsLoading"
import { logoutAbnormalFunction } from "../function/logoutAbnormalFunction"
import { comparePriceValidator, intValidate } from "../validators/publicValidator"
import { setShopDataHandlerType, categoryMediumType, initApiModelType, categoryMastersResponseDataType } from "../constance/typeModel"


export const useEntryContentsShopHooks = (navigateByAgent: Function) => {
    const [response,setResponse] = useState(initCategoryResponse)
    const {state, dispatch} = useContext(Context)
    const [showFlag, setShowFlag] = useState<boolean>(false)
    const [errMsg] = useState<string>("")

    //セッションデータ抽出
    const agentMasterInfoData = getSession('agent_master')
    const merchantInfoData = getSession('merchant')
    const economicInfoData = getSession('economic_condition')
    const userInfoData = getSession('user')
    const ipFlag = userInfoData.ip_flag
    

    //コンテキスト抽出
    //ショップ
    const shopState = useMemo(()=>{
        if(state.entry.shop === null || state.entry.shop === initShopSessionData) return getSession('shop')
        return state.entry.shop
    },[state.entry.shop])
    

    // ファイル
    const fileState = useMemo(()=>{
        if(state.entry.files === null || state.entry.files === initFileSessionData) return getSession('files')
        return state.entry.files
    },[state.entry.files])
    // 商品コンテンツによる出し分け
    const reviewContentsDivisionState = useMemo(()=>{
        if(state.entry.review_contents_division === null || state.entry.review_contents_division === initReviewContentsDivisionSessionData) return getSession('review_contents_division')
        return state.entry.review_contents_division
    },[state.entry.review_contents_division])

    const validateErrMsgs = state.validateMessages.shop
    const apiErrMsg = useMemo(()=>{return state.auth.err_message},[state.auth.err_message])
    
    // ショップ情報をコンテキストとセッションに保存
    const setShopDataHandler = (data: setShopDataHandlerType) => {
        const insertData = {...shopState, ...data}
        setShopInfoData(insertData)
        dispatch({
            type : "set-entry-shop",
            payload : insertData
        })
    }

    // 商品コンテンツにより出し分け情報をコンテキストとセッションに保存
    const setReviewContentsDivisionDataHandler = (data: categoryMediumType) => {
        const insertData = {...reviewContentsDivisionState, ...data}
        setReviewContentsDivisionInfoData(insertData)
        dispatch({
            type : "set-review-content-division",
            payload : insertData
        })
    }

    //自動スクロールトップ&ローディング画面を非表示
    const scrollWindow = useCallback(() => {
        window.scrollTo(0,0)
        setIsLoading(false,dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // 画面読み込み時処理
    useEffect(() => {
        //ローディング画面開始
        setIsLoading(true, dispatch)
        // 審査コンテンツ取得
        const getResponse = async () => {
            let response: initApiModelType<categoryMastersResponseDataType> = initApiResponse 
            const data = { entry_id: userInfoData.entry_id }
            response = await GetMethod<{ entry_id: number }, categoryMastersResponseDataType>(data, '/category_masters')
            if(response.code !== 200){
                if(response.code === 401){
                    return logoutAbnormalFunction(response, dispatch, navigateByAgent, state.agentPath)
                }
                return navigateByAgent('/system_error')
            }  
            if(response.code === 200)  setResponse(response)
            setShowFlag(true)
        } 
        getResponse()
        setTimeout(scrollWindow,1500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //getエラーハンドル
    const normal = () => {
        navigateByAgent('/entry/shop')
    }
    const abnormal = () => {
        navigateByAgent('/entry_top')
    }
    
    //取扱商品コード大項目
    const selectMajorContents = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false, memo: '' },
        ]
        if(!showFlag || !response.response_data) return initArray
        for (let key in response.response_data.category_major) {
            const majorObj = { label: response.response_data.category_major[key].name, value: response.response_data.category_major[key].zeus_major_code, disabled: false, memo: key }
            initArray.push(majorObj)
        }
        return initArray
    }, [response,showFlag])

    //取扱商品コード中項目
    const selectMediumContents = useMemo(() => {
        const initArray = [
            { label: '選択してください', value: '', disabled: false, memo: '' },
        ]
        if(!showFlag || !response.response_data) return initArray
        let majorNumber: string = "";
        if(shopState.shop_addition_major_content_code === null) return initArray
        selectMajorContents.forEach((key) => {
            if (key.value === shopState.shop_addition_major_content_code) majorNumber = key.memo
        })
        if (shopState.shop_addition_major_content_code !== '') {
            const categoryMedium = response.response_data.category_major[majorNumber].category_medium

            for (let key in categoryMedium) {
                const mediumObj = { label: categoryMedium[key].name, value: categoryMedium[key].zeus_medium_code, disabled: false, memo: key }
                initArray.push(mediumObj)
            }
        }
        return initArray
    }, [response, selectMajorContents, shopState.shop_addition_major_content_code,showFlag])


    //出し分けのためのオブジェクト
    const ObjToDevide = useMemo(() => {
        let majorNumber: string = "";
        const allFalse = initCategoryMediumDevided
        if(!showFlag || !response.response_data) return allFalse
        if (shopState.shop_addition_major_content_code !== '' && shopState.shop_addition_medium_content_code !== '' && shopState.shop_addition_medium_content_code !== null) {
            selectMajorContents.forEach((key) => {
                if (key.value === shopState.shop_addition_major_content_code) majorNumber = key.memo
            })
            let mediumNumber: string = "";
            selectMediumContents.forEach((key) => {
                if (key.value === shopState.shop_addition_medium_content_code) mediumNumber = key.memo
            })
            
            const result = response.response_data.category_major[majorNumber].category_medium[mediumNumber]
            return result
        } else {
            return allFalse
        }
    }, [response,shopState.shop_addition_medium_content_code,shopState.shop_addition_major_content_code,selectMajorContents,selectMediumContents,showFlag])

    //入力時に即座にバリデーションをかける関数
    const onClickValidator = (e: React.ChangeEvent<HTMLInputElement>) => {
        let insertObject = {}
        const validateMessage = itemValidators("shop",e.target.id,e.target.value)
        insertObject = {[e.target.id]:validateMessage}
        if(e.target.id === "shop_addition_price_range_min"){
            insertObject = {
		...insertObject,
		shop_addition_price_validate: comparePriceValidator({shop_addition_price_range_min: e.target.value, shop_addition_price_range_max: shopState.shop_addition_price_range_max})
	    }
        } else if(e.target.id === "shop_addition_price_range_max"){
            insertObject = {
		...insertObject,
		shop_addition_price_validate: comparePriceValidator({shop_addition_price_range_min: shopState.shop_addition_price_range_min, shop_addition_price_range_max: e.target.value})
	    }
        }
        dispatch({
            type:"set-validate-shop",
            payload:{
                ...state.validateMessages.shop,
                ...insertObject
            }
        })
    }

    //テキストセットハンドラ
    const setShopStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let majorLabel;
        let mediumLabel;
        let value = e.target.value
        onClickValidator(e)
        if(e.target.id === 'shop_addition_major_content_code'){
            selectMajorContents.forEach((obj)=>{
                if(obj.value === e.target.value){
                    majorLabel = obj.label
                }
            })
            return setShopDataHandler({shop_addition_major_content_code: e.target.value, shop_addition_major_content : majorLabel, shop_addition_medium_content_code:"", shop_addition_medium_content:""})
        }
        if(e.target.id === 'shop_addition_medium_content_code'){
            selectMediumContents.forEach((obj)=>{
                if(obj.value === e.target.value){
                    mediumLabel = obj.label
                }
            })
            return setShopDataHandler({shop_addition_medium_content : mediumLabel, shop_addition_medium_content_code:e.target.value, })
        }
        if((e.target.id === 'shop_addition_price_range_min' || e.target.id === 'shop_addition_price_range_max') && intValidate(value) === "" && value !== ""){
            return setShopDataHandler({[e.target.id]:Number(e.target.value) })
        }
        
        return setShopDataHandler({[e.target.id]: value})
    }
    //ラジオボタンハンドラ
    const setRadioShopStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const typeBoolean = JSON.parse(e.target.value.toLowerCase())
        if(e.target.name === 'shop_addition_subscription_flag') return setShopDataHandler({[e.target.name]: typeBoolean, shop_addition_use_continuation: typeBoolean })
        return setShopDataHandler({[e.target.name]: typeBoolean })
    }
    //ラジオボタンハンドラ
    const setBtoBRadioShopStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setShopDataHandler({[e.target.name]: e.target.value })
    }
    //セレクトメニュー
    const setSelectBoxShopStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        return setShopDataHandler({[e.target.id]: e.target.checked })
    }

    //サイト状態ラジオボタン
    const radioItemsSite = [
        { label: 'オープン済み', value: 1 },
        { label: '準備中', value: 0 },
        // { label: '資料・テストページ', value: 3 },
    ]
    // サイト状態文言
    const radioItemsSiteMethod = [
        { label: 'サンプルサイト・デモサイトによる審査', value: 0 },
        { label: '資料による審査', value: 1 },
    ]
    //YesNoラジオボタン
    const radioYesNo = [
        { label: 'あり', value: 'true' },
        { label: 'なし', value: 'false' },
    ]
    //YesNo反転ラジオボタン
    const reverseRadioYesNo = [
        { label: 'あり', value: 'false' },
        { label: 'なし', value: 'true' },
    ]
    //予約商品ラジオボタン
    const radioCanNot = [
        { label: '２か月以内提供可', value: 'true' },
        { label: '２か月以内提供不可', value: 'false' },
    ]
    //BtoBラジオボタン（仮）
    const radioBtoB = [
        { label: 'なし', value: "1" },
        { label: '卸を一部含む', value: "2" },
        { label: '卸のみ', value: "3" },
    ]

    //運営開始セレクトメニュー
    //年
    const selectStartOperationYear = useMemo(() => {
        let today = new Date()
        let thisYear = today.getFullYear()
        const initArray: { label: string | number, value: string | number, disabled: boolean }[] = [
            { label: '選択してください', value: '', disabled: false },
        ]
        for (let i = thisYear+1; i > 1970; i--) {
            initArray.push({ label: i, value: i, disabled: false })
        }
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //月
    const selectStartOperationMonth = useMemo(() => {
        const initArray: { label: string | number, value: string | number, disabled: boolean }[] = [
            { label: '選択してください', value: '', disabled: false },
        ]
        for (let i = 1; i < 13; i++) {
            initArray.push({ label: i, value: i, disabled: false })
        }
        return initArray
    }, [])

    //課金サイクルセレクトメニュー
    const selectChargesCycle = useMemo(() => {
        const initArray = [
            { label: '課金サイクルを選択', value: '', disabled: false },
            { label: '１か月未満（毎週『月２回』 等）', value: 1, disabled: false },
            { label: '１か月毎', value: 2, disabled: false },
            { label: '３か月毎', value: 3, disabled: false },
            { label: '６か月毎', value: 4, disabled: false },
            { label: '１２か月毎', value: 5, disabled: false },
            { label: '１３か月以上', value: 6, disabled: false },
            { label: 'その他', value: 9, disabled: false },
        ]
        return initArray
    }, [])

    //サービス提供までの期間セレクトメニュー
    const selectPeriodUpToOfferService = useMemo(() => {
        const initArray = [
            { label: '期間を選択', value: '期間を選択', disabled: false },
            { label: '2ヶ月以内', value: 1, disabled: false },
            { label: '3ヶ月以上', value: 2, disabled: false },
        ]
        return initArray
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //コンビニ払込票サイト名を顧客通知店舗名に補完
    useEffect(() => {
            if(!economicInfoData.atobarai.system_setting.select_flag) return 
            let value = shopState.shop_cvs_website_name !== "" ? shopState.shop_cvs_website_name.replace(/-/g,'－') : ""
            setShopDataHandler({shop_atobarai_customer_notice_store_name:value})
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shopState.shop_cvs_website_name,economicInfoData.atobarai.system_setting.select_flag])

    //商品価格帯のmin<max判定
    useEffect(()=>{
        let messageObj = { isValidate: '', message: '' }
        const minPrice = shopState.shop_addition_price_range_min
        const maxPrice = shopState.shop_addition_price_range_max
        if((minPrice === null || maxPrice === null) || (minPrice === "" || maxPrice === "") || (intValidate(maxPrice) !== "" || intValidate(minPrice) !== "")) return
        if(maxPrice < minPrice) messageObj.message = "商品の最小値が最大値を上回っています。"
        dispatch({
            type:"set-validate-shop",
            payload:{
                ...state.validateMessages.shop,
                shop_addition_price_validate:messageObj
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[shopState.shop_addition_price_range_min,shopState.shop_addition_price_range_max,dispatch,])

    //レンタル規約もしくはファイルどちらかが必須のバリデーション
    const rentalValidateData = useMemo(() => {
        let rentalValidateData = false;
        if((shopState.shop_addition_rental_product_url !== "" && shopState.shop_addition_rental_product_url !== null) || fileState.rental.length !== 0){
            rentalValidateData = true;
        }
        return rentalValidateData
    },[shopState.shop_addition_rental_product_url, fileState.rental])

    //サービスの提供場所を1つにまとめる
    const shopAdditionOfferPlaceData = useMemo(() => {
        let shopAdditionOfferPlaceData = false;
        if(shopState.shop_addition_online === true || shopState.shop_addition_offline === true){
            shopAdditionOfferPlaceData = true
        }
        return shopAdditionOfferPlaceData
        
    },[shopState.shop_addition_online,shopState.shop_addition_offline])

    //商品コンテンツを1つにまとめる
    const productString = useMemo(()=>{
        let productArray: string[] = []
        if(shopState.shop_addition_product_content_code_sell_goods) productArray.push('SELL_GOODS')
        if(shopState.shop_addition_product_content_code_service) productArray.push('SERVICE')
        if(shopState.shop_addition_product_content_code_digital_email) productArray.push('DIGITAL_EMAIL')

        if(productArray.length === 2){
            productArray.splice(1,0,',')
        }else if(productArray.length === 3){
            productArray.splice(1,0,',')
            productArray.splice(3,0,',')
        }
        const productString = productArray.join("")
        return productString
    },[shopState.shop_addition_product_content_code_sell_goods,shopState.shop_addition_product_content_code_service,shopState.shop_addition_product_content_code_digital_email,])

    //site_statusを選択した項目によって変更する
    const siteStatusData = useMemo(()=>{
        let siteStatusData = '';
        if(shopState.shop_addition_site_status_code === 0){
            if(shopState.shop_addition_site_status_method === 0){
                siteStatusData = "0"
            }else if(shopState.shop_addition_site_status_method === 1){
                siteStatusData = "2"
            }
        }else if(shopState.shop_addition_site_status_code === 1){
            siteStatusData = "1"
        }
        return siteStatusData
    },[shopState.shop_addition_site_status_code,shopState.shop_addition_site_status_method])

    // 審査コンテンツ出し分けをコンテキストにセット＋オンライン・現地フラグがtrueに変更された場合にonlineとofflineの項目を保持する
    useEffect(()=>{
        if(ObjToDevide.online_local_flag) setShopDataHandler({shop_addition_offline: shopState.shop_addition_offline, shop_addition_online: shopState.shop_addition_online})
        setReviewContentsDivisionDataHandler(ObjToDevide)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ObjToDevide])

    //データセットのuseEffectを一つにまとめる
    useEffect(()=>{
        let setData:{[key:string]: boolean|string} = {
	    shop_addition_site_status:siteStatusData,
	    shop_addition_product_content_code:productString,
	    shop_addition_offer_place:shopAdditionOfferPlaceData,
	    shop_addition_rental_validate:rentalValidateData,
	    shop_addition_btob_special_rate_flag:ObjToDevide.special_rate_btob_flag,
	    shop_addition_high_risk_flag:ObjToDevide.highrisk_flag
        }
	if([3,4,5].includes(userInfoData.agent_master_id)){
	    setData = {
		...setData,
                shop_addition_use_continuation: true
	    }
	}

        setShopDataHandler(setData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[siteStatusData,rentalValidateData,productString,shopAdditionOfferPlaceData,ObjToDevide.special_rate_btob_flag,ObjToDevide.highrisk_flag,userInfoData.agent_master_id])

    return { userInfoData, merchantInfoData, agentMasterInfoData,shopState, economicInfoData,errMsg, setShopStateHandler, setRadioShopStateHandler, setSelectBoxShopStateHandler, 
        radioItemsSite, radioItemsSiteMethod, radioYesNo, radioCanNot, radioBtoB, selectStartOperationYear, selectStartOperationMonth, selectChargesCycle, selectPeriodUpToOfferService, 
        selectMajorContents, selectMediumContents, ObjToDevide, response, normal, abnormal, validateErrMsgs, apiErrMsg, showFlag, reverseRadioYesNo, setBtoBRadioShopStateHandler, ipFlag}
}
