import styled from "styled-components";
import { FlexBox } from "../../../layouts/FlexBox";
import { colors } from "../../../../css/variables/colors"; 
import { FormWrap } from "../../../layouts/FormWrap"; 
import { Button, SelectMenu, OptionList, RadioButtonGroup, ToolChip } from "../../../ui-parts/ocnk";
import { Checkbox } from "../../../ui-parts/ocnk/Checkbox";
import useEconomicSelectElementHooks from "../../../../hooks/useEconomicSelectElementHooks";
import { fonts } from "../../../../css/variables/fonts";
import { AccodionToggle } from "../../../ui-parts/ocnk/AccodionToggle";
import { toolChipList } from "../../../../constance/constance";
import { mixins } from "../../../../css/variables/mixins";
import RecomendPop from "../../../ui-parts/ocnk/RecomendPop";
import useNavigateByAgent from "../../../../hooks/useNavigateByAgentHooks";
import CvsDescriptionPop from "../../../ui-parts/ocnk/CvsDescriptionPop";

type titleWrapper = { toggle: boolean }
const TitleWrapper = styled.div<titleWrapper>`
	display:flex;
	justify-content:space-between;
	align-items: center;
  .toggleArrow {
    width: 60px;
    height: 28px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s linear;
    ${props => props.toggle ? 'transform: rotate(180deg)' : null};
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -15%) rotate(-45deg);
      width: 12px;
      height: 12px;
      border-top: solid 2px ${colors.themeColor('text_lightgray')};
      border-right: solid 2px ${colors.themeColor('text_lightgray')};
    }
  }
`
const ContentWrapper = styled.div<titleWrapper>`
  ${props => props.toggle ? 'height: auto' : 'height: 0px'};
  ${props => props.toggle ? 'overflow: visible' : 'overflow: hidden'};
  transition: all 0.3s linear;
  .inner {
    padding: 28px;
  }
`
const CheckboxWrapper = styled.div`
  width:100%;
`
const UL = styled.ul`
  list-style: inherit;
  padding-left:20px;
  li {
	font-size:${fonts.fontSizeRem('ms')};
	${mixins.screenUnder()}{
		font-size:${fonts.fontSizeRem('xs')}
	}
  }
`
const RequiredStyle = styled.span`
  font-size:${fonts.fontSizeRem('xxs')};
  color: ${colors.themeColor('text_white')};
  background: ${colors.themeColor('background_required')};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  vertical-align: text-bottom;
`
const FeeWrapper = styled.div`
	p{
		margin-bottom:0px;
		color:${colors.themeColor('text_main')}
	}
	.blue{
		color:${colors.themeColor('primary_dark')};
	}
	.main-color{
		color:${colors.themeColor('text_main')}
	}
	.normal-weight{
		font-weight:normal;
	}
	.cvs-settle-wrap{
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		margin-top:12px;
	}
	.cvs-settle-fee{
		width:250px;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
	}
	.credit-settle-fee{
		width:300px;
		padding:4px;
		display:flex;
		justify-content:space-between;
		border-bottom:solid 1px ${colors.themeColor('border_box')};
		${mixins.screenUnder()}{
			width: 100%;
		}
	}
	.cvs-settle-fee-p{
		display:inline-block;
		font-size:14px;
	}
`
const OptionWrapper = styled.div`
	width:100%;
	font-size:${fonts.fontSizeRem('ms')};
`
const MainTitleStyled = styled.p`
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: ${fonts.fontSizeRem('xl')};
	font-weight: 800;
	white-space: pre-line;
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('l')};
	}
`
const TitleStyled = styled.p`
	margin-top: 0px;
	margin-bottom: 0px;
	font-size: ${fonts.fontSizeRem('m')};
	font-weight: 600;
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('ms')};
	}
`
const FeeUnit = styled.p`
	font-size: ${fonts.fontSizeRem('s')};
	margin-bottom: 0px;
	color:${colors.themeColor('primary_dark')};
	white-space: nowrap;
`
type feeFlexWrapperType = {
	responsiveNoFlex?:boolean
}
const FeeFlexWrapper = styled.div<feeFlexWrapperType>`
	width:100%;
	display: flex;
	justify-content: space-between;
	vertical-align: bottom;
	font-weight: 600;
	${mixins.screenUnder()}{
		${props => props.responsiveNoFlex ? 'display: block;' : ''}
	}
`
const OptionFeeStyle = styled.div`
	margin-top:4px;
	color:${colors.themeColor('primary_dark')};
	font-weight: 600;
`
const OptionServiceWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width:100%;
`
const SystemFeeWrapper = styled.div`
	width:300px;
`
const FontXLSpan = styled.span`
	font-size: ${fonts.fontSizeRem('xl')};
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('xl')};
	}
`
const ServiceNameStyle = styled.div`
	margin-bottom: 0px;
	font-size: ${fonts.fontSizeRem('ms')};
	${mixins.screenUnder()}{
		display: flex;
		justify-content: space-between;
		font-size: ${fonts.fontSizeRem('s')};
	}
`
const PaymentSiteStyle = styled.h3`
	font-weight: normal;
	margin-bottom: 0;
	font-size: ${fonts.fontSizeRem('ms')};
	${mixins.screenUnder()}{
		font-size: ${fonts.fontSizeRem('s')};	
	}
`

const MainTitleStyledSpan = styled.span`
	font-size: ${fonts.fontSizeRem('ms')};
`
const EconomicSelectElement = () => {
	const {navigateByAgent} = useNavigateByAgent()
	const {creditFee,cvsFee,sumFeeState,economicDevidedInfoData,economicConditionMasterInfoData,optionExistFlg,systemSettingMasterInfoData,validateState,demandCode,ToggleState,economicState,paymentSiteCreditPhrase,selectItemsPaymentMethod,setToggleStateHandler,navigateBack,navigateEconomicEstimate,setEconomicInfoDataHandler,setMonthlyPaymentMethodHandler,paymentSiteRadio,setPaymentSiteRadioHandler,optionExistFlag,radioEarlyPaymentRef,monthlyPaymentRef} = useEconomicSelectElementHooks(navigateByAgent);
    return(
        <FormWrap width={700} padding={36}>
			{economicDevidedInfoData.credit &&
				<AccodionToggle 
					headtitle={
					<div>
						<MainTitleStyled>クレジットカード決済</MainTitleStyled>
						<p className="mb0 font-xs opacity">お申し込みが必須です</p>
					</div>
					}
					credit
					toggle={true}
				>
					<>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>初期費用（開設契約金）</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.credit.opening_contract_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper responsiveNoFlex>
								<TitleStyled>取引手数料</TitleStyled>
								<FeeWrapper>
									{creditFee}
								</FeeWrapper>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FlexBox column align={'flex-end'}>
								<FeeFlexWrapper>
									<TitleStyled>月額費用（システム利用料）</TitleStyled>
									<FeeUnit>￥<FontXLSpan>{sumFeeState.credit}</FontXLSpan> /月</FeeUnit>
								</FeeFlexWrapper>
								{demandCode === 'system' &&
									<SystemFeeWrapper>
										{economicState.credit.system_setting.threed_secure &&
											<OptionList service={"credit"} option={"threed_secure"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.cvv &&
											<OptionList service={"credit"} option={"cvv"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.subscription &&
											<OptionList service={"credit"} option={"subscription"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.div &&
											<OptionList service={"credit"} option={"div"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.send_mail &&
											<OptionList service={"credit"} option={"send_mail"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.id_password_storage &&
											<OptionList service={"credit"} option={"id_password_storage"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.payment_now &&
											<OptionList service={"credit"} option={"payment_now"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.system_setting.customer_support &&
											<OptionList service={"credit"} option={"customer_support"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.credit.economic_requirement.early_payment_flag &&
											<>
												<FlexBox spaceBetween>
													<p className="mb0 font-s">早期入金サービス</p>
													{economicState.credit.economic_requirement.payment_site === economicConditionMasterInfoData.credit.payment_site &&
														<p className="mb0 font-s">無料</p>	
													}
													{economicState.credit.economic_requirement.payment_site === "月2回締め 月2回払い" &&
														<p className="mb0 font-s">￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_early_deposit_service_two}</span> /月</p>	
													}
													{economicState.credit.economic_requirement.payment_site === "月3回締め 月3回払い" &&
														<p className="mb0 font-s">￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_early_deposit_service_three}</span> /月</p>	
													}
												</FlexBox>
												<hr className="mb4 mt0"/>
											</>
										}
									</SystemFeeWrapper>
								}
							</FlexBox>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>売上処理料</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.credit.sales_processing_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<TitleWrapper toggle={ToggleState.payment_site_credit}>
								<TitleStyled>支払いサイト(入金サイクル)</TitleStyled>
								<div className="toggleArrow" onClick={()=>setToggleStateHandler('payment_site_credit')}></div>
							</TitleWrapper>
							<ContentWrapper toggle={ToggleState.payment_site_credit}>
								<PaymentSiteStyle>{economicState.credit.economic_requirement.payment_site}</PaymentSiteStyle>
								<p className="mb0 font-xs opacity">{paymentSiteCreditPhrase}</p>	
								{economicState.credit.economic_requirement.early_payment_flag ? 
									<p className="mt8 mb0 font-xs">※月末の支払日が土日祝日の場合は、「前営業日」での支払いになりますが、月末以外の支払日が土日祝日の場合は、「翌営業日」での支払いになります。</p> : 
									<p className="mt8 mb0 font-xs">※金融機関が休業日の場合は翌営業日支払いとなります。</p>
								}
							</ContentWrapper>
						</FormWrap>
						{optionExistFlg &&
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.option_service_credit}>
									<FlexBox column gap={8}>
										<TitleStyled>オプションサービスのご利用</TitleStyled>
										{demandCode !== 'system' &&
										<p className="mb0 font-xs opacity">オプションサービスの支払い方法は、各月の売上金から相殺となります。</p>
										}
									</FlexBox>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('option_service_credit')}></div>
								</TitleWrapper>
								<ContentWrapper toggle={ToggleState.option_service_credit}>
									{/* {optionExistFlag.credit.default &&
										<FlexBox column mt={20} gap={0}>
											<h4 className="mb0 mt16">　付帯サービス</h4>
											{(systemSettingMasterInfoData.credit_3d_agent_flag && !systemSettingMasterInfoData.credit_3d_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・3Dセキュア（本人認証システム）　< ToolChipcontent={toolChipList.threed_secure} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_cvv_agent_flag && !systemSettingMasterInfoData.credit_cvv_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・セキュリティコード（CVV2/CVC2）　<ToolChip content={toolChipList.cvv} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_subscription_agent_flag && !systemSettingMasterInfoData.credit_subscription_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・継続決済　<ToolChip content={toolChipList.subscription} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && !systemSettingMasterInfoData.credit_div2_bonus_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・２回払い/ボーナス一括払い</ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_send_mail_agent_flag && !systemSettingMasterInfoData.credit_send_mail_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && !systemSettingMasterInfoData.credit_id_password_storage_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・ID・パスワード預かり　<ToolChip content={toolChipList.id_password} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_customer_support_agent_flag && !systemSettingMasterInfoData.credit_customer_support_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.credit_payment_now_agent_flag && !systemSettingMasterInfoData.credit_payment_now_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・今すぐ決済　<ToolChip content={toolChipList.payment_now} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
										</FlexBox>
									} */}
									{optionExistFlag.credit.option &&
										<FlexBox column mt={20} gap={8}>
											<TitleStyled>　選択可能サービス</TitleStyled>
											{/* {(systemSettingMasterInfoData.credit_3d_agent_flag && systemSettingMasterInfoData.credit_3d_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_threed_secure' label="3Dセキュア（本人認証システム）を利用する" toolChip={toolChipList.threed_secure} responsivePlacement={'right'} checked={economicState.credit.system_setting.threed_secure} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','threed_secure')} disabled={!systemSettingMasterInfoData.credit_3d_merchant_flag}/>
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_3d_secure}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{(systemSettingMasterInfoData.credit_cvv_agent_flag && systemSettingMasterInfoData.credit_cvv_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_cvv' label="セキュリティコード（CVV2/CVC2）を利用する" toolChip={toolChipList.cvv} responsivePlacement={'right'} checked={economicState.credit.system_setting.cvv} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','cvv')} disabled={!systemSettingMasterInfoData.credit_cvv_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_cvv}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{(systemSettingMasterInfoData.credit_subscription_agent_flag && systemSettingMasterInfoData.credit_subscription_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_subscription' label="継続決済を利用する" toolChip={toolChipList.subscription} responsivePlacement={'right'} checked={economicState.credit.system_setting.subscription} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','subscription')} disabled={!systemSettingMasterInfoData.credit_subscription_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_continuous_billing}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											} */}
											{(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && systemSettingMasterInfoData.credit_div2_bonus_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_div' label="２回払い/ボーナス一括払いを利用する" checked={economicState.credit.system_setting.div} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','div')} disabled={!systemSettingMasterInfoData.credit_div2_bonus_merchant_flag} />
													<OptionFeeStyle>　　 ￥<FontXLSpan>{economicConditionMasterInfoData.credit.option_fee_div2_bonus}</FontXLSpan> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{/* {(systemSettingMasterInfoData.credit_send_mail_agent_flag && systemSettingMasterInfoData.credit_send_mail_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_send_mail' label="決済結果通知メールを利用する" toolChip={toolChipList.send_mail} responsivePlacement={'right'} checked={economicState.credit.system_setting.send_mail} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','send_mail')} disabled={!systemSettingMasterInfoData.credit_send_mail_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_confirmation_email}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && systemSettingMasterInfoData.credit_id_password_storage_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_id_password' label="ID・パスワード預かりを利用する" toolChip={toolChipList.id_password} responsivePlacement={'right'} checked={economicState.credit.system_setting.id_password_storage} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','id_password_storage')} disabled={!systemSettingMasterInfoData.credit_id_password_storage_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_id_password_storage}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{(systemSettingMasterInfoData.credit_customer_support_agent_flag && systemSettingMasterInfoData.credit_customer_support_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_customer_support' label="カスタマーサポートを利用する" toolChip={toolChipList.customer_support} responsivePlacement={'right'} checked={economicState.credit.system_setting.customer_support} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','customer_support')} disabled={!systemSettingMasterInfoData.credit_customer_support_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_customer_support}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											}
											{(systemSettingMasterInfoData.credit_payment_now_agent_flag && systemSettingMasterInfoData.credit_payment_now_merchant_flag )&&
												<OptionServiceWrapper>
													<Checkbox id='credit_payment_now' label="今すぐ決済を利用する" toolChip={toolChipList.payment_now} responsivePlacement={'right'} checked={economicState.credit.system_setting.payment_now} onChange={()=>setEconomicInfoDataHandler('credit','system_setting','payment_now')} disabled={!systemSettingMasterInfoData.credit_payment_now_merchant_flag} />
													<OptionFeeStyle>　　 ￥<span className="font-ms">{economicConditionMasterInfoData.credit.option_fee_payment_now}</span> /月</OptionFeeStyle>
												</OptionServiceWrapper>
											} */}
											{(systemSettingMasterInfoData.credit_early_payment_service_agent_flag && systemSettingMasterInfoData.credit_early_payment_service_merchant_flag )&&
												<CheckboxWrapper>
													<Checkbox id='credit_early_payment' label="早期入金サービスを利用する" toolChip={toolChipList.early_payment} responsivePlacement={'right'} toolChipWidth={450} checked={economicState.credit.economic_requirement.early_payment_flag} disabled={!systemSettingMasterInfoData.credit_early_payment_service_merchant_flag} onChange={()=>setEconomicInfoDataHandler('credit','economic_requirement','early_payment_flag')} />
													{economicState.credit.economic_requirement.early_payment_flag && 
														<>
															<RadioButtonGroup name='credit_payment_site_radio' radioitems={paymentSiteRadio} state={economicState.credit.economic_requirement.payment_site} setState={setPaymentSiteRadioHandler} iserror={validateState.credit.early_payment} refProp={radioEarlyPaymentRef}/>
															<p className="font-xs mt12">・月3回締め3回払いの場合、特定月（4月、10月、12月）の未締め分の支払日は、翌月15日となりますのであらかじめご注意ください。</p>
														</>
													}
												</CheckboxWrapper>
											}
										</FlexBox>
									}
								</ContentWrapper>
							</FormWrap>
						}
						{(systemSettingMasterInfoData.credit_auth_agent_flag || systemSettingMasterInfoData.credit_cgi_agent_flag ) &&
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.system_option_credit}>
									<div>
										<TitleStyled>付帯サービス</TitleStyled>
										<p className="mb0 font-xs mt8 opacity">以下機能は、基本機能として付いてます。</p>
									</div>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('system_option_credit')}></div>
								</TitleWrapper>
								<ContentWrapper toggle={ToggleState.system_option_credit}>
									<FlexBox column mt={20} gap={8}>
										{/* {systemSettingMasterInfoData.credit_auth_agent_flag && */}
											<OptionWrapper>
												<ServiceNameStyle>・仮売上決済　<ToolChip content={toolChipList.auth} placement={'left'} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {systemSettingMasterInfoData.credit_cgi_agent_flag &&
											<OptionWrapper>
												<ServiceNameStyle>・CGI　<ToolChip content={toolChipList.threed_secure} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
										{/* {(systemSettingMasterInfoData.credit_3d_agent_flag && !systemSettingMasterInfoData.credit_3d_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・3Dセキュア（本人認証システム）　<ToolChip content={toolChipList.threed_secure} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.credit_cvv_agent_flag && !systemSettingMasterInfoData.credit_cvv_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・セキュリティコード（CVV2/CVC2）　<ToolChip content={toolChipList.cvv} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.credit_div2_bonus_agent_flag && !systemSettingMasterInfoData.credit_div2_bonus_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・分割払い/リボルビング（物品販売のみ利用可能）</ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.credit_subscription_agent_flag && !systemSettingMasterInfoData.credit_subscription_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・継続決済（対象の取扱商品またはサービスがある場合）<ToolChip content={toolChipList.subscription} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.credit_send_mail_agent_flag && !systemSettingMasterInfoData.credit_send_mail_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.credit_id_password_storage_agent_flag && !systemSettingMasterInfoData.credit_id_password_storage_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・ID・パスワード預かり　<ToolChip content={toolChipList.id_password} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
										{/* {(systemSettingMasterInfoData.credit_customer_support_agent_flag && !systemSettingMasterInfoData.credit_customer_support_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
										{/* {(systemSettingMasterInfoData.credit_payment_now_agent_flag && !systemSettingMasterInfoData.credit_payment_now_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・今すぐ決済　<ToolChip content={toolChipList.payment_now} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
									</FlexBox>
								</ContentWrapper>
							</FormWrap>
						}
					</>
				</AccodionToggle>
			}
			
			{/* あと払い決済 */}
			{economicDevidedInfoData.atobarai &&
				<AccodionToggle 
					headtitle={
						<FlexBox column responsiveGap={0} mt={0}>
							<MainTitleStyled>{'コンビニあと払い'}<MainTitleStyledSpan>{'（未回収リスクを100％保証）'}</MainTitleStyledSpan></MainTitleStyled>
							<RecomendPop/>
						</FlexBox>
						
					} 
					onClick={()=>setEconomicInfoDataHandler('atobarai','system_setting','select_flag')} 
					toggle={economicState.atobarai.system_setting.select_flag}
				>
					<>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>初期費用（開設契約金）</TitleStyled>
								<FeeUnit>￥<span className="font-xl">{economicConditionMasterInfoData.atobarai.opening_contract_fee}</span></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>決済手数料</TitleStyled>
								<FeeUnit><span className="font-xl">{economicConditionMasterInfoData.atobarai.business_operator_fee}％</span></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>月額費用（システム利用料）</TitleStyled>
								<FeeUnit>￥<span className="font-xl">{sumFeeState.atobarai} /月</span></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>請求処理料</TitleStyled>
								<FeeUnit>￥<span className="font-xl">{economicConditionMasterInfoData.atobarai.sales_processing_fee}</span></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<TitleWrapper toggle={ToggleState.payment_site_atobarai}>
								<TitleStyled>支払いサイト(入金サイクル)</TitleStyled>
								<div className="toggleArrow" onClick={()=>setToggleStateHandler('payment_site_atobarai')}></div>
							</TitleWrapper>
							<ContentWrapper toggle={ToggleState.payment_site_atobarai}>
								<h3 className="normal mb0 font-m">{economicConditionMasterInfoData.atobarai.payment_site}</h3>
								<p className="mb0 font-xs opacity">無料</p>
								<p className="mt8 mb0 font-xs">※金融機関が休業日の場合は翌営業日支払いとなります。</p>
							</ContentWrapper>
						</FormWrap>
					</>
				</AccodionToggle>
			}


			{/* 銀行振り込み */}
			{economicDevidedInfoData.bank &&
				<AccodionToggle headtitle={<MainTitleStyled>ネットバンク決済（入金おまかせサービス）</MainTitleStyled>} onClick={()=>setEconomicInfoDataHandler('bank','system_setting','select_flag')} toggle={economicState.bank.system_setting.select_flag}>
					<>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>初期費用（開設契約金）</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.bank.opening_contract_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>取引手数料</TitleStyled>
								<FeeUnit><FontXLSpan>{economicConditionMasterInfoData.bank.fee}</FontXLSpan>％</FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FlexBox column align={'flex-end'}>
								<FeeFlexWrapper>
									<TitleStyled>月額費用（システム利用料）</TitleStyled>
									<FeeUnit>￥<FontXLSpan>{sumFeeState.bank}</FontXLSpan> /月</FeeUnit>
								</FeeFlexWrapper>
								{demandCode === 'system' &&
									<SystemFeeWrapper>
										{economicState.bank.system_setting.send_mail &&
											<OptionList service={"bank"} option={"send_mail"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.bank.system_setting.customer_support &&
											<OptionList service={"bank"} option={"customer_support"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.bank.economic_requirement.early_payment_flag &&
											<OptionList service={"bank"} option={"early_payment_flag"} masterData={economicConditionMasterInfoData}/>
										}
									</SystemFeeWrapper>
								}
							</FlexBox>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>売上処理料</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.bank.sales_processing_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						{/* {((systemSettingMasterInfoData.bank_send_mail_agent_flag && systemSettingMasterInfoData.bank_send_mail_merchant_flag) || (systemSettingMasterInfoData.bank_customer_support_agent_flag && systemSettingMasterInfoData.bank_customer_support_merchant_flag) || (systemSettingMasterInfoData.bank_early_payment_service_agent_flag && systemSettingMasterInfoData.bank_early_payment_service_merchant_flag)) &&
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.option_service_bank}>
									<FlexBox column gap={8}>
										<TitleStyled>オプションサービスのご利用</TitleStyled>
										{demandCode !== 'system' &&
											<p className="mb0 font-xs opacity">オプションサービスの支払い方法は、各月の売上金から相殺となります。</p>
										}
									</FlexBox>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('option_service_bank')}></div>
								</TitleWrapper><ContentWrapper toggle={ToggleState.option_service_credit}>
									{optionExistFlag.bank.default &&
										<FlexBox column mt={20} gap={0}>
											<h4 className="mb0 mt16">　付帯サービス</h4>
											{(systemSettingMasterInfoData.bank_send_mail_agent_flag && !systemSettingMasterInfoData.bank_send_mail_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.bank_customer_support_agent_flag && !systemSettingMasterInfoData.bank_customer_support_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.bank_early_payment_service_agent_flag && !systemSettingMasterInfoData.bank_early_payment_service_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・早期入金サービス　<ToolChip content={toolChipList.early_payment} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
										</FlexBox>
									}
									{optionExistFlag.bank.option &&
										<FlexBox column mt={20}>
											<h4 className="mb0 mt0">　選択可能サービス</h4>
											{(systemSettingMasterInfoData.bank_send_mail_agent_flag && systemSettingMasterInfoData.bank_send_mail_merchant_flag )&&
												<div>
													<Checkbox id='bank_send_mail' label="決済結果通知メールを利用する" toolChip={toolChipList.send_mail} responsivePlacement={'right'} checked={economicState.bank.system_setting.send_mail} disabled={!systemSettingMasterInfoData.bank_send_mail_merchant_flag} onChange={()=>setEconomicInfoDataHandler('bank','system_setting','send_mail')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.bank.option_fee_confirmation_email}</span> /月</p>
												</div>
											}
											{(systemSettingMasterInfoData.bank_customer_support_agent_flag && systemSettingMasterInfoData.bank_customer_support_merchant_flag )&&
												<div>
													<Checkbox id='bank_customer_support' label="カスタマーサポートを利用する" toolChip={toolChipList.customer_support} responsivePlacement={'right'} checked={economicState.bank.system_setting.customer_support} disabled={!systemSettingMasterInfoData.bank_customer_support_merchant_flag} onChange={()=>setEconomicInfoDataHandler('bank','system_setting','customer_support')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.bank.option_fee_customer_support}</span> /月</p>
												</div>
											}
											{(systemSettingMasterInfoData.bank_early_payment_service_agent_flag && systemSettingMasterInfoData.bank_early_payment_service_merchant_flag )&&
												<div>
													<Checkbox id='bank_early_payment' label="早期入金サービスを利用する" toolChip={toolChipList.early_payment} responsivePlacement={'right'} checked={economicState.bank.economic_requirement.early_payment_flag} disabled={!systemSettingMasterInfoData.bank_early_payment_service_merchant_flag} onChange={()=>setEconomicInfoDataHandler('bank','economic_requirement','early_payment_flag')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.bank.option_fee_early_deposit_service}</span> /月</p>
												</div>
											}
										</FlexBox>
									}
								</ContentWrapper>
							</FormWrap>
						} */}
						<FormWrap margin_tb={12} padding={16} width={594}>
							<TitleWrapper toggle={ToggleState.payment_site_bank}>
								<TitleStyled>支払いサイト(入金サイクル)</TitleStyled>
								<div className="toggleArrow" onClick={()=>setToggleStateHandler('payment_site_bank')}></div>
							</TitleWrapper>
							<ContentWrapper toggle={ToggleState.payment_site_bank}>
								<PaymentSiteStyle>{economicConditionMasterInfoData.bank.payment_site}</PaymentSiteStyle>
								<p className="mb0 font-xs opacity">無料</p>
								<p className="mt8 mb0 font-xs">※金融機関が休業日の場合は翌営業日支払いとなります。</p>
							</ContentWrapper>
						</FormWrap>
						{systemSettingMasterInfoData.bank_cgi_agent_flag && 
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.system_option_bank}>
									<div>
										<TitleStyled>付帯サービス</TitleStyled>
										<p className="mb0 font-xs mt8 opacity">以下機能は、基本機能として付いてます。</p>
									</div>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('system_option_bank')}></div>
								</TitleWrapper>
								<ContentWrapper toggle={ToggleState.system_option_bank}>
									<FlexBox column mt={20} gap={8}>
										{/* {(systemSettingMasterInfoData.bank_cgi_agent_flag && !systemSettingMasterInfoData.bank_cgi_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・CGI　<ToolChip content={toolChipList.cgi} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
										{/* {(systemSettingMasterInfoData.bank_send_mail_agent_flag && !systemSettingMasterInfoData.bank_send_mail_merchant_flag )&& */}
											<OptionWrapper>
												<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										{/* } */}
										{/* {(systemSettingMasterInfoData.bank_customer_support_agent_flag && !systemSettingMasterInfoData.bank_customer_support_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										}
										{(systemSettingMasterInfoData.bank_early_payment_service_agent_flag && !systemSettingMasterInfoData.bank_early_payment_service_merchant_flag )&&
											<OptionWrapper>
												<ServiceNameStyle>・早期入金サービス　<ToolChip content={toolChipList.early_payment} responsivePlacement={'right'}/></ServiceNameStyle>
											</OptionWrapper>
										} */}
									</FlexBox>
								</ContentWrapper>
							</FormWrap>
						}
					</>
				</AccodionToggle>
			}
			{/* コンビニ決済 */}
			{economicDevidedInfoData.cvs &&
				<AccodionToggle headtitle={
				                    <FlexBox column gap={0} responsiveGap={0} mt={0}>
							<MainTitleStyled>コンビニ決済</MainTitleStyled>
							<CvsDescriptionPop/>
						    </FlexBox>
			                        }
					       	onClick={()=>setEconomicInfoDataHandler('cvs','system_setting','select_flag')} 
						toggle={economicState.cvs.system_setting.select_flag}>
					<>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>初期費用（開設契約金）</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.cvs.opening_contract_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<TitleStyled>取引手数料</TitleStyled>
							<FeeWrapper>
								<div className="cvs-settle-wrap">
									{cvsFee}
								</div>
							</FeeWrapper>
							<p className="mt12 mb0 font-s">※50,000円（税抜）以上の決済の場合、別途、収入印紙代200円をご負担頂きます。</p>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FlexBox column align={'flex-end'}>
								<FeeFlexWrapper>
									<TitleStyled>月額費用（システム利用料）</TitleStyled>
									<FeeUnit>￥<FontXLSpan>{sumFeeState.cvs}</FontXLSpan> /月</FeeUnit>
								</FeeFlexWrapper>
								{demandCode === 'system' &&
									<SystemFeeWrapper>
										{economicState.cvs.system_setting.send_mail &&
											<OptionList service={"cvs"} option={"send_mail"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.cvs.system_setting.customer_support &&
											<OptionList service={"cvs"} option={"customer_support"} masterData={economicConditionMasterInfoData}/>
										}
										{economicState.cvs.economic_requirement.early_payment_flag &&
											<OptionList service={"cvs"} option={"early_payment_flag"} masterData={economicConditionMasterInfoData}/>
										}
									</SystemFeeWrapper>
								}
							</FlexBox>
						</FormWrap>
						{/* {((systemSettingMasterInfoData.cvs_send_mail_agent_flag && systemSettingMasterInfoData.cvs_send_mail_merchant_flag) || (systemSettingMasterInfoData.cvs_customer_support_agent_flag && systemSettingMasterInfoData.cvs_customer_support_merchant_flag) || (systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag)) &&
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.option_service_cvs}>
									<FlexBox column gap={8}>
										<TitleStyled>オプションサービスのご利用</TitleStyled>
										{demandCode !== 'system' &&
											<p className="mb0 font-xs opacity">オプションサービスの支払い方法は、各月の売上金から相殺となります。</p>
										}
									</FlexBox>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('option_service_cvs')}></div>
								</TitleWrapper>
								<ContentWrapper toggle={ToggleState.option_service_credit}>
									{optionExistFlag.cvs.default &&
										<FlexBox column mt={20} gap={0}>
											<h4 className="mb0 mt16">　付帯サービス</h4>
											{(systemSettingMasterInfoData.cvs_send_mail_agent_flag && !systemSettingMasterInfoData.cvs_send_mail_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.cvs_customer_support_agent_flag && !systemSettingMasterInfoData.cvs_customer_support_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
											{(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && !systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag )&&
												<OptionWrapper>
													<ServiceNameStyle>・早期入金サービス　<ToolChip content={toolChipList.early_payment} responsivePlacement={'right'}/></ServiceNameStyle>
												</OptionWrapper>
											}
										</FlexBox>
									}
									{optionExistFlag.cvs.option &&
										<FlexBox column mt={20}>
											<h4 className="mb0 mt0">　選択可能サービス</h4>
											{(systemSettingMasterInfoData.cvs_send_mail_agent_flag && systemSettingMasterInfoData.cvs_send_mail_merchant_flag )&&
												<div>
													<Checkbox id='cvs_send_mail' label="決済結果通知メールを利用する" toolChip={toolChipList.send_mail} responsivePlacement={'right'} checked={economicState.cvs.system_setting.send_mail} disabled={!systemSettingMasterInfoData.cvs_send_mail_merchant_flag} onChange={()=>setEconomicInfoDataHandler('cvs','system_setting','send_mail')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.cvs.option_fee_confirmation_email}</span> /月</p>
												</div>
											}
											{(systemSettingMasterInfoData.cvs_customer_support_agent_flag && systemSettingMasterInfoData.cvs_customer_support_merchant_flag )&&
												<div>
													<Checkbox id='cvs_customer_support' label="カスタマーサポートを利用する" toolChip={toolChipList.customer_support} responsivePlacement={'right'} checked={economicState.cvs.system_setting.customer_support} disabled={!systemSettingMasterInfoData.cvs_customer_support_merchant_flag} onChange={()=>setEconomicInfoDataHandler('cvs','system_setting','customer_support')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.cvs.option_fee_customer_support}</span> /月</p>
												</div>
											}
											{(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag )&&
												<div>
													<Checkbox id='cvs_early_payment' label="早期入金サービスを利用する" toolChip={toolChipList.early_payment} responsivePlacement={'right'} checked={economicState.cvs.economic_requirement.early_payment_flag} disabled={!systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag} onChange={()=>setEconomicInfoDataHandler('cvs','economic_requirement','early_payment_flag')} />
													<p className="mb0 font-xs opacity">　　 ￥<span className="font-ms">{economicConditionMasterInfoData.cvs.option_fee_early_deposit_service}</span> /月</p>
												</div>
											}
										</FlexBox>
									}
								</ContentWrapper>
							</FormWrap>
						} */}
						<FormWrap margin_tb={12} padding={16} width={594}>
							<FeeFlexWrapper>
								<TitleStyled>売上処理料</TitleStyled>
								<FeeUnit>￥<FontXLSpan>{economicConditionMasterInfoData.cvs.sales_processing_fee}</FontXLSpan></FeeUnit>
							</FeeFlexWrapper>
						</FormWrap>
						<FormWrap margin_tb={12} padding={16} width={594}>
							<TitleWrapper toggle={ToggleState.payment_site_cvs}>
								<TitleStyled>支払いサイト(入金サイクル)</TitleStyled>
								<div className="toggleArrow" onClick={()=>setToggleStateHandler('payment_site_cvs')}></div>
							</TitleWrapper>
							<ContentWrapper toggle={ToggleState.payment_site_cvs}>
								<PaymentSiteStyle>{economicConditionMasterInfoData.cvs.payment_site}</PaymentSiteStyle>
								<p className="mb0 font-xs opacity">無料</p>	
								<p className="mt8 mb0 font-xs">※金融機関が休業日の場合は翌営業日支払いとなります。</p>
							</ContentWrapper>
						</FormWrap>
						{systemSettingMasterInfoData.cvs_cgi_agent_flag &&
							<FormWrap margin_tb={12} padding={16} width={594}>
								<TitleWrapper toggle={ToggleState.system_option_cvs}>
									<div>
										<TitleStyled>付帯サービス</TitleStyled>
										<p className="mb0 font-xs mt8 opacity">以下機能は、基本機能として付いてます。</p>
									</div>
									<div className="toggleArrow" onClick={()=>setToggleStateHandler('system_option_cvs')}></div>
								</TitleWrapper>
								<ContentWrapper toggle={ToggleState.system_option_cvs}>
									<FlexBox column mt={20} gap={8}>
										{/* {systemSettingMasterInfoData.cvs_cgi_agent_flag &&
											<> */}
												{/* {(systemSettingMasterInfoData.cvs_cgi_agent_flag && !systemSettingMasterInfoData.cvs_cgi_merchant_flag )&&
													<OptionWrapper>
														<ServiceNameStyle>・CGI　<ToolChip content={toolChipList.cgi} responsivePlacement={'right'}/></ServiceNameStyle>
													</OptionWrapper>
												} */}
												{/* {(systemSettingMasterInfoData.cvs_send_mail_agent_flag && !systemSettingMasterInfoData.cvs_send_mail_merchant_flag )&& */}
													<OptionWrapper>
														<ServiceNameStyle>・決済結果通知メール　<ToolChip content={toolChipList.send_mail} responsivePlacement={'right'}/></ServiceNameStyle>
													</OptionWrapper>
												{/* } */}
												{/* {(systemSettingMasterInfoData.cvs_customer_support_agent_flag && !systemSettingMasterInfoData.cvs_customer_support_merchant_flag )&&
													<OptionWrapper>
														<ServiceNameStyle>・カスタマーサポート　<ToolChip content={toolChipList.customer_support} responsivePlacement={'right'}/></ServiceNameStyle>
													</OptionWrapper>
												}
												{(systemSettingMasterInfoData.cvs_early_payment_service_agent_flag && !systemSettingMasterInfoData.cvs_early_payment_service_merchant_flag )&&
													<OptionWrapper>
														<ServiceNameStyle>・早期入金サービス　<ToolChip content={toolChipList.early_payment} responsivePlacement={'right'}/></ServiceNameStyle>
													</OptionWrapper>
												} */}
											{/* </>

										} */}
									</FlexBox>
								</ContentWrapper>
							</FormWrap>
						}
					</>
				</AccodionToggle>
			}
			{ demandCode === "system" && 
				<FormWrap  margin_tb={12} padding={16} width={625} refProp={monthlyPaymentRef}>
					<TitleWrapper toggle={ToggleState.payment_method_credit}>
						<TitleStyled>支払い方法{sumFeeState.credit !== 0 && <RequiredStyle>必須</RequiredStyle>}</TitleStyled>
						<div className="toggleArrow" onClick={()=>setToggleStateHandler('payment_method_credit')}></div>
					</TitleWrapper>
					<ContentWrapper toggle={ToggleState.payment_method_credit}>
					{(sumFeeState.credit === 0 && sumFeeState.bank === 0 && sumFeeState.cvs === 0 && sumFeeState.atobarai === 0) ? 
						<p className="mb0 font-ms">無料</p>	:
						<SelectMenu id='monthly_payment_method' name='monthly_payment_method' options={selectItemsPaymentMethod} defaultValue={economicState.credit.economic_requirement.monthly_payment_method} onChange={setMonthlyPaymentMethodHandler} iserror={validateState.credit.monthly_payment_method}></SelectMenu>
					}
					</ContentWrapper>
				</FormWrap>
			}
			<UL>
				<li>上記料金は「コンビニあと払いの決済手数料」を除きすべて税別表記となります。</li>
				<li>支払サイト：支払日が金融機関休業日の場合、翌営業日の支払いとさせていただきます。</li>
				<li>支払サイトの<span className="bold">振込手数料はゼウス負担</span>となります。</li>
				<li>月3回締め3回払いの場合、特定月（4月、10月、12月）の未締め分の支払日は、翌月15日となりますのであらかじめご注意ください。</li>
				<li>手数料の支払い方法は、各月の売上金から相殺となります。</li>
			</UL>
			<FlexBox mt={24} gap={16}>
				<Button variant="secoundary" onClick={navigateBack}>戻る</Button>
				<Button onClick={navigateEconomicEstimate}>利用料金を確認する</Button>
			</FlexBox>
		</FormWrap>
    )
}
export default EconomicSelectElement
